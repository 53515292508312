import React, { useEffect, useRef, useState} from 'react'
import * as styles from './challenges.module.scss'
import { Content } from '../../../../../../utilities'
import { Link } from 'gatsby'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
     <div className={styles.headingContainer}>
        <div className={styles.numContainer}>
          <img src={'./button-orange.svg'} className={styles.orange} />
          <p className={styles.number}>3</p>
        </div>
        <h2 className={styles.heading}>
          <Content text={block.primaryChallengesHeading} />
        </h2>
      </div>
      <div className={styles.container}>
        {block.primaryChallenges.map((challenge, i) => (
          <div
            key={challenge.text}
            className={styles.challenge}
            style={{ transitionDelay: `.${i}s`}}
          >
            <div className={styles.items}>
              <img src={challenge.icon} className={styles.icon} />
              <p>
                <Content text={challenge.text} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}