import React, { useContext } from 'react'
import { Context } from '../../context'
import * as styles from './searchResults.module.scss'
import { Link } from 'gatsby'
import { Content, Trim } from '../'

export default ({ results }) => {
  const context = useContext(Context);

  return (
    <section className={styles.section + ` ${context?.search ? `${styles.show}` : ''}`}>
      <button
        className={styles.close}
        onClick={() => {
          if (context) {
            context.setSearch('');
          }
        }}
      >
        <Content text={'close'} />
      </button>
      <h1 className={styles.heading}>
        <Content text={`Search results for '${context && context.search}': ${results.length}`} />
      </h1>
      {results && results.map((result, i) => (
        <Link 
          key={i}
          to={result.slug.startsWith('/') ? result.slug : `/${result.slug}`}
          className={styles.resultContainer}
          onClick={() => context && context.setSearch('')}>
          <div className={styles.result}>
            <Trim text={result.label} classname={styles.label} />
            <Trim text={result.text} classname={styles.text}/>
          </div>
        </Link>
      ))}
    </section>
  )
}