import React from 'react'
import { Benchmarks, CanopyGoal, Challenges, Footer, Header, Keystones } from './components'
import FooterForm from '../footerForm'
import * as styles from './infographic.module.scss'

export default ({ block, scroll }) => {


  return (
    <section className={styles.section}>
      <Header block={block} scroll={scroll} />
      <CanopyGoal block={block} scroll={scroll} />
      <Keystones block={block} scroll={scroll} />
      <Benchmarks block={block} scroll={scroll} />
      <Challenges block={block} scroll={scroll} />
      <div className={styles.form}>
        <FooterForm block={block} scroll={scroll} />
      </div>
      <Footer block={block} scroll={scroll} />
    </section>
  )
}