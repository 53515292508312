export default (label, formValues, checkEmail, checkZip, isObject, checkObject) => {
  if (formValues[label]) {
    if (label.includes('Email') && checkEmail(formValues[label]))
      return true;
    else if (label.includes('Phone') && formValues[label].length === 10)
      return true;
    else if (label.includes('Zip') && checkZip(formValues[label]))
      return true;
    else if (isObject(label) && checkObject(formValues[label]))
      return true;
    else if (!label.includes('Email') && !label.includes('Phone') && !label.includes('Zip') && !isObject(label) && formValues[label].length > 0)
      return true;
    return false;
  }
  return false;
}