// extracted by mini-css-extract-plugin
export var active = "footerForm-module--active--CweAe";
export var arrow = "footerForm-module--arrow--u3Wiv";
export var button = "footerForm-module--button--5qCkV";
export var colOne = "footerForm-module--colOne--jnqKY";
export var donationWidget = "footerForm-module--donation-widget--KNdH5";
export var dropdownInput = "footerForm-module--dropdownInput--5+kFY";
export var dropdownOption = "footerForm-module--dropdownOption--p-WqK";
export var footerText = "footerForm-module--footerText--X6WKZ";
export var form = "footerForm-module--form--NM3TG";
export var formClose = "footerForm-module--formClose--101k9";
export var formContainer = "footerForm-module--formContainer--LTC9n";
export var formWrapper = "footerForm-module--formWrapper--lUtKn";
export var heading = "footerForm-module--heading--8VLr7";
export var hide = "footerForm-module--hide--BEWci";
export var icon = "footerForm-module--icon--6eMno";
export var image = "footerForm-module--image--+c2lG";
export var input = "footerForm-module--input--UhCnD";
export var label = "footerForm-module--label--rValp";
export var left = "footerForm-module--left--8hySg";
export var loader = "footerForm-module--loader--+IFoM";
export var message = "footerForm-module--message--VHh3n";
export var noText = "footerForm-module--noText--hvuS3";
export var open = "footerForm-module--open--jUD1v";
export var placeholder = "footerForm-module--placeholder--xiIIX";
export var right = "footerForm-module--right--0cB-U";
export var section = "footerForm-module--section--AWiLd";
export var select = "footerForm-module--select--F12U5";
export var show = "footerForm-module--show--LIPkX";
export var wide = "footerForm-module--wide--KD8v4";