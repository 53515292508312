import React, { useState, useRef, useEffect } from 'react'
import * as styles from './contact.module.scss'
import { Content, SlideoutForm } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [formOneOpen, setFormOneOpen] = useState(false);
  const [formTwoOpen, setFormTwoOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100)
  }, [])

  const handleClick = i => {
    if (i === 0) {
      setFormOneOpen(!formOneOpen);
    } else if (i === 1) {
      setFormTwoOpen(!formTwoOpen);
    }
  }

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.left + ` ${show ? `${styles.show}` : ''}`}>
        <Content text={block.text} />
        <div className={styles.buttons}>
          {block.buttons.map((button, i) => (
            <button
              type='button'
              className={styles.button}
              onClick={() => handleClick(i)}
            >
              <Content text={button.buttonText} /><span><img src='/button.svg' className={styles.icon} /></span>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.right + ` ${show ? `${styles.show}` : ''}`}>
        <img src={block.image} className={styles.image} />
      </div>
      {block.buttons.map((button, i) => (
        <SlideoutForm
          block={block}
          formOpen={i === 0 ? formOneOpen : formTwoOpen}
          setFormOpen={i === 0 ? setFormOneOpen : setFormTwoOpen}
          title={button.buttonText}
        />
      ))}
    </section>
  )
}