export default (e, setFormValues) => {
  const { name, value, type, checked } = e.target;
  setFormValues(values => {
    const copy = {...values};
    if (type === 'checkbox') {
      copy[name][value] = checked;
    }
    else if (name.includes('Phone') && (isNaN(+value) || value.length > 10))
      return copy;
    else if (name.includes('Zip')) {
      if (value.length > 10 || isNaN(+value.replace('-', ''))) {
        return copy;
      } else if (value.length <= 6) {
        copy[name] = value.replace('-', '');
        return copy;
      } else if (value.length > 5) {
        copy[name] = value.slice(0,5) + '-' + value.slice(6);
        return copy;
      }
    } else copy[name] = value;
    
    return copy;
  })
}