// extracted by mini-css-extract-plugin
export var button = "simpleForm-module--button---A3At";
export var colOne = "simpleForm-module--colOne--2NbAt";
export var form = "simpleForm-module--form--mUWxV";
export var heading = "simpleForm-module--heading--vGRLx";
export var icon = "simpleForm-module--icon--yT7yz";
export var input = "simpleForm-module--input--p2i-e";
export var label = "simpleForm-module--label--UoVvA";
export var message = "simpleForm-module--message--rXGAn";
export var pageHeading = "simpleForm-module--pageHeading--5aVC0";
export var section = "simpleForm-module--section--O+v7t";
export var show = "simpleForm-module--show--6D53q";
export var submitted = "simpleForm-module--submitted--XT5-f";