import React, { useEffect, useContext, useState } from 'react'
import { Context } from '../../../../context'
import { Content } from '../../../../utilities'
import * as styles from './rainfall.module.scss'

export default ({ block }) => {
  const context = useContext(Context);
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    if (context && !amount) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const todayString = today.toISOString().slice(0,10);
      const yestString = yesterday.toISOString().slice(0,10);
      const location = context.location;
      
      fetch(`https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${location}/${yestString}/${todayString}?unitGroup=us&key=${process.env.GATSBY_RAINFALL_KEY}&include=obs`)
      .then(res => res.json())
      .then(jsonRes => {
        if (jsonRes && jsonRes['days'] && jsonRes['days'].length) {
          const total = jsonRes['days'][0].precip + jsonRes['days'][1].precip;
          setAmount(total.toFixed(1));
        }
      })
      .catch(error => console.log(error));
    }

  }, [context && context.location])

  return (
    <section className={styles.section}>
      <p className={styles.text}><Content text='Rainfall' /></p>
      <div className={styles.box}>
        <svg viewBox="0 0 100 100">
          <path className={styles.tear}
                d="M15 6
                  Q 15 6, 23 22
                  A 11 15 0 1 1 6 21
                  Q 15 6 15 6z" />
        </svg>
        {amount &&
        <p className={styles.number}>{amount}"</p>}
      </div>
    </section>
  )
}