import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import { Content } from '../../../../utilities'
import SharedTextButton from '../sharedTextButton'
import * as styles from './availableSpecies.module.scss'
import * as scrollFn from 'scroll'
import { Context } from '../../../../context'

export default ({ padding=null, block, trees, scroll, category, moveStart=4 }) => {
  const ref = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const [show, setShow] = useState(false);
  const [moves, setMoves] = useState(moveStart);
  const [filteredSpecies, setFilteredSpecies] = useState(null);
  const context = useContext(Context)
 
  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    const reset = () => {
      if (ref.current) {
        ref.current.scrollLeft = 0;
        if (window.innerWidth > 767) {
          setMoves(4);
        } else {
          setMoves(1);
        }
        setShowLeft(false);
        setShowRight(true);
      }
    }
    reset();
    window.addEventListener('resize', reset)

    return (() => {
      window.removeEventListener('resize', reset)
    })
  }, [])

  useEffect(() => {
    if (category && ref.current) {
      ref.current.scrollLeft = 0;
      setShowRight(true);
      setShowLeft(false);
      if (window.innerWidth > 767) {
        setMoves(moveStart || 4)
      } else if (window.innerWidth <= 767) {
        setMoves(1);
      }
    }
  }, [category])

  const moveRight = () => {
    let distance;
    if (window.innerWidth > 767) {
      distance = window.innerWidth / 100 * 18;
    } else {
      distance = window.innerWidth / 100 * 83;
    }
    if (ref.current) {
      setShowLeft(true);
      scrollFn.left(ref.current, ref.current.scrollLeft + distance)
      if (moves === block.species.length - 1)
        setShowRight(false);
      setMoves(moves + 1);
    }
  }

  const moveLeft = () => {
    let distance;
    if (window.innerWidth > 767) {
      distance = window.innerWidth / 100 * 18;
    } else {
      distance = window.innerWidth / 100 * 83;
    }
    if (ref.current) {
      setShowRight(true);
      scrollFn.left(ref.current, ref.current.scrollLeft - distance)
      setMoves(moves - 1);
      if (window.innerWidth > 767 && moveStart && moves === moveStart + 1)
        setShowLeft(false);
      else if(window.innerWidth > 767 && !moveStart && moves === 4)
        setShowLeft(false);
      else if (window.innerWidth <= 767 && moves === 2) {
        setShowLeft(false);
      }
    }
  }

  const filterSpecies = tree => {
    if (block.species.length && block.species.includes(tree.fields['Common Name'])) {
      return tree;
    }
  }

  const parseSun = text => {
    if (text.includes('Full'))
      return 'Full'
    else if (text.includes('Partial'))
      return 'Partial'
    else if (text.includes('Shade'))
      return 'Shade'
  }

  useEffect(() => {
    if (trees) {
      setFilteredSpecies(trees.filter(filterSpecies));
    }
  }, [trees])

  const treeImages = context?.treeImages ?? {}

  const getImage = (id) => {
    const attachments = treeImages[id]
    return attachments ? attachments[0]?.thumbnails?.large.url : null
  }

  const sort = (a,b) => {
    let comparison = 0;
    const nameOne = a.fields['Common Name'];
    const nameTwo = b.fields['Common Name'];
    if (nameTwo > nameOne) {
      comparison = -1;
    } else if (nameOne > nameTwo) {
      comparison = 1;
    }
    return comparison;
  }

  return (
    <section className={styles.section + ` ${block.speciesTheme === 'dark' ? `${styles.dark}` : ''}`} id={block.template && block.template.replace('main-', '')}>
      {block.hasButton &&
      <div className={styles.buttonContainer}>
        <SharedTextButton button={block.button} />
      </div>}
      {block.blockTitleSpecies &&
      <h2 className={styles.heading + ` ${block.speciesHeadingSize === 'small' ? ` ${styles.small}` : ''}`}><Content text={block.blockTitleSpecies} /></h2>}
      <div 
        className={styles.trees + ` ${show ? `${styles.show}` : ''}`
          + ` ${!block.speciesHasPadding ? `${styles.noPadding}` : ''}` + ` ${padding ? `${styles.customPadding}` : ''}`}
        ref={ref}
      >
        {block.species.length && filteredSpecies && filteredSpecies.sort(sort).map(({fields, id}, i) => (
          <Link key={fields['Common Name']} to={`/${fields['Common Name'].toLowerCase().split('/')[0].split(' ').join('-')}`} className={styles.tree}>
            <div className={styles.imageContainer}>
              {getImage(id) ? ( 
                <img src={getImage(id)} className={styles.image} />
              ) : <img src={''} className={styles.image} />}
            </div>
            <h4 className={styles.name}><Content text={fields['Common Name']} /></h4>
            <p className={styles.sun}><Content text={`Sun preference: ${fields['Sun'] && parseSun(fields['Sun'])}`} /></p>
            <p className={styles.growth}><Content text={`Growth rate: ${fields['Growth Rate'] && fields['Growth Rate']}`} /></p>
            <p className={styles.height}><Content text={`Mature height: ${fields['Height (Ft.)'] && fields['Height (Ft.)'] + ' feet'}`} /></p>
          </Link>
        ))}
        <div className={styles.padding} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.footerText}>
          {block.eventsFooterText &&
            <Content text={block.eventsFooterText} />}
        </div>
        <div className={styles.buttons}>
          <button 
            className={styles.left + ` ${!showLeft ? `${styles.hide}` :''}`}
            onClick={moveLeft}><img src='/button.svg' className={styles.leftIcon} />
          </button>
          <button 
            className={styles.right + ` ${!showRight ? `${styles.hide}` :''}`}
            onClick={moveRight}><img src='/button.svg' className={styles.rightIcon} />
          </button>
        </div>
      </div>
      {block.eventsHasBackground &&
      <div className={styles.background} />}
    </section>
  )
}