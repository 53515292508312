import React, { useState, useEffect, useRef } from 'react'
import * as styles from './masterPlan.module.scss'
import parse from 'html-react-parser'
import SharedTextButton from '../sharedTextButton'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <img 
        src={block.image} 
        className={styles.image + ` ${show ? `${styles.show}` : ''}`} 
      />
      <div 
        className={styles.background + ` ${show ? `${styles.show}` : ''}`} 
      >
        <div className={styles.heading}>
          <Content text={block.blockTitle} />
        </div>
        <div className={styles.buttonContainer + ` ${show ? `${styles.show}` : ''}`}>
          <SharedTextButton button={block.button} />
        </div>
      </div>
    </section>
  )
}