import React, { useState, useEffect, useRef } from 'react'
import * as styles from './timeline.module.scss'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const slider = useRef(null);
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);
  const [active, setActive] = useState(null);
  const [index, setIndex] = useState(0);

  const ascending = (a, b) => {
    const timeOne = +a.fields.Year;
    const timeTwo = +b.fields.Year;
    let comparison = 0;
    if (timeOne > timeTwo) {
      comparison = 1;
    } else if (timeOne < timeTwo) {
      comparison = -1;
    }
    return comparison;
  } 

  useEffect(() => {
    fetch('/.netlify/functions/timeline', {
      method: 'POST',
    })
    .then(res => res.json())
    .then(response => {
      if (response.data) {
        const filtered = response.data.filter(event => event.fields.Year);
        setEvents(filtered);
      }
    })
  }, [])

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    if (slider.current && typeof window !== 'undefined' && window.innerWidth <= 767) {
      const children = slider.current.children;
      if (children.length) {
        for (let i = 0; i < children.length; i++) {
          if (children[i].id === `button${index}`) {
            const width = window.innerWidth;
            const left = slider.current.scrollLeft;
            const target = (width / 2);
            const elementLeft = children[i].getBoundingClientRect().left;
              slider.current.scrollLeft = left + (elementLeft - target + width / 100 * 10);
          }
        }
      }
    }
  }, [index])

  const getSize = (i) => {
    return `${70 + i * 12}%`
  }

  const getMobileSize = (i) => {
    return `${155 + i * 12}%`
  }

  const getDistance = (i) => {
    return `${5 - i * 6}%`
  }

  const getMobileDistance = (i) => {
    return -26 - i * 6
  }

  const getCircleR = i => {
    return ((90 + (events.length - 1) * 3) - i * 3).toString()
  }

  const getMobileCircleR = i => {
    return ((195 + (events.length - 1) * 3) - i * 2.8).toString()
  }

  const ascendingEvents = [...events];
  ascendingEvents.sort(ascending);
  const descendingEvents = [...ascendingEvents];
  descendingEvents.reverse();

  useEffect(() => {
    if (!active) {
      setActive(ascendingEvents[0]);
    }
  }, [ascendingEvents])

  const [hoverActive, setHoverActive] = useState(null);
  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.times}>
        <div className={styles.container} ref={slider}>
          <p className={styles.start}>{ascendingEvents[0] && ascendingEvents[0].fields.Year}</p>
          {ascendingEvents && ascendingEvents.map((event, i) => (
            <div key={event.id} className={styles.yearContainer + ` ${active && active.id === event.id ? `${styles.active}` : ''}`} id={`button${i}`}>
              <button className={styles.yearBtn} onClick={() => {setActive(event); setIndex(i)}} />
              <span className={styles.year}>{event.fields.Year}</span>
            </div>
          ))}
          <p className={styles.end}>{ascendingEvents[0] && ascendingEvents[ascendingEvents.length - 1].fields.Year}</p>
          <div className={styles.padding} />
        </div>
      </div>
      <div className={styles.eventContainer} style={{marginLeft: `${events.length - 10}%`}}>
        {ascendingEvents.filter((event, i) => i <= ascendingEvents.length / 5).map((event, i) => (
          <div 
            key={i}
            className={styles.event}
            style={{
              width: getSize(i),
              height: getSize(i),
              top: getDistance(i),
              left: getDistance(i),
              zIndex: events.length - i
            }}>
              <div className={styles.images}>
                <img 
                  src={'/treeslice.png'}
                  className={styles.tree}
                />
              </div>
          </div>
        ))}
        <svg className={styles.circles} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          {descendingEvents.map((event, i) => (
            <circle key={i} cx="50" cy="49" r={getCircleR(i)} strokeWidth="3" fill="transparent" className={styles.circle + ` ${active && active.id === event.id ? `${styles.active}` : ''}`}
              onMouseDown={() => setActive(event)}
              onMouseOver={() => setHoverActive(event)}
              onMouseLeave={() => setHoverActive(null)}
            />
          ))}
        </svg>
        <div 
          className={styles.imageContainer}>
          <img 
            src={active && active.fields.Attachments[0].thumbnails.large.url}
            className={styles.image}
          />
          <div className={styles.textContainer}>
            <p className={styles.text}>
              {active && <Content text={active.fields.Notes} />}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.mobileEventContainer}>
        {ascendingEvents.filter((event, i) => i <= ascendingEvents.length / 5).map((event, i) => (
          <div 
            key={i}
            className={styles.event}
            style={{
              width: getMobileSize(i),
              height: getMobileSize(i),
              top: `${getMobileDistance(i) - 26}%`,
              left: `${getMobileDistance(i)}%`,
              zIndex: events.length - i
            }}>
              <div className={styles.images}>
                <img 
                  src={'/treeslice.png'}
                  className={styles.tree}
                />
              </div>
          </div>
        ))}
        <svg className={styles.circles} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          {descendingEvents.map((event, i) => (
            <circle key={i} cx="50" cy="49" r={getMobileCircleR(i)} strokeWidth="3" fill="transparent" className={styles.circle + ` ${active && active.id === event.id ? `${styles.active}` : ''}`}
              onMouseDown={() => {setActive(event); setIndex(descendingEvents.length - 1 - i)}}
              onMouseOver={() => setHoverActive(event)}
              onMouseLeave={() => setHoverActive(null)}
            />
          ))}
        </svg>
        <div 
          className={styles.imageContainer}>
          <img 
            src={active && active.fields.Attachments[0].thumbnails.large.url}
            className={styles.image}
          />
          <div className={styles.textContainer}>
            <p className={styles.text}>
              {active && <Content text={active.fields.Notes} />}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}