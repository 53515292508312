import React, { useEffect, useState, useContext } from 'react'
import * as styles from './contactForm.module.scss'
import { checkEmail, checkZip, Content, checkValidInputs, handleChange, getTranslation } from '../../../../utilities'
import { Context } from '../../../../context'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ block, show }) => {
  const context = useContext(Context);
  const [placeholders, setPlaceholders] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleInputChange = e => {
    handleChange(e, setFormValues);
  }

  useEffect(() => {
    const translateText = async (text) => {
      if (context && context.language !== 'en') {
        const result = await getTranslation(text, context.language);
        if (result['data'][0]) {
          setPlaceholders(placeholders => {
            const copy = [...placeholders];
            copy.push(result['data'][0]);
            return copy;
          });
        }
      } else setPlaceholders(placeholders => {
          const copy = [...placeholders];
          copy.push(text);
          return copy;
      })
    }
    setPlaceholders([]);
    block.fields.forEach(async field => {
      await translateText(field.label);
    })

  }, [context && context.language])

  useEffect(() => {
    if (block.inputs) {
      const inputs = {};
      block.fields.forEach(input => {
        if (input.label)
        inputs[input.label] = '';
      })
      setFormValues(inputs);
    }
  }, [])

  const isObject = label => {
    return typeof formValues[label] === 'object' && formValues[label] !== null
  }

  const checkObject = object => {
    let hasChecked = false;
    for (let [key, value] of Object.entries(object)) {
      if (value)
        hasChecked = true;
    }
    return hasChecked;
  }

  const checkIfValid = label => {
    return (
      checkValidInputs(label, formValues, checkEmail, checkZip, isObject, checkObject)
    )
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    block.fields.forEach(input => {
      if (!checkIfValid(input.label)) {
        setShowErrorMessage(true);
        error = true;
      }
    })
    if (!error) {
      setShowErrorMessage(false);
      setLoader(true);
      fetch('/.netlify/functions/contact', {
        method: 'POST',
        body: JSON.stringify({
          ...formValues,
          emails: block.emails,
          form: 'Footer Form'
        })
      })
      .then(response => {
        setLoader(false);
        setSubmitted(true);
        const inputs = {};
        if (block.fields) {
          const inputs = {};
          block.fields.forEach(input => {
            if (input.label)
            inputs[input.label] = '';
          })
          setFormValues(inputs);
        }
      })
    }
  }
 
  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      <h3 className={styles.heading}>
        <Content text={block.label} />
      </h3>
      <form className={styles.form} onSubmit={handleSubmit}>
        {block.fields.map(({label}, i) => (
          <div key={label} className={styles.field}>
            <input 
              className={styles.input + ` ${i === block.fields.length - 1 ? `${styles.narrow}` : ''}`}
              id={`contact-${label}`}
              name={label}
              value={formValues[label]}
              placeholder={placeholders[i]}
              onChange={handleInputChange}
              type={label.includes('Phone') ? 'number' : 'text'}
            />
          </div>
        ))}
        <button className={styles.button} style={{backgroundImage: `url('/icon-light-green.svg')`}}>
          <img src='/arrow-right-dark-green.svg' />
        </button>
        {loader &&
          <div className={styles.loader}>
            <Loader
              type="TailSpin"
              color="#0C3507"
              height={50}
              width={50}
            />
          </div>}
          {showErrorMessage &&
          <p className={styles.message}>
            <Content text='Please enter valid input' />.
          </p>}
          {submitted &&
          <p className={styles.message}>
            <Content text={`Thanks, we'll be in touch!`} />.
          </p>}
      </form>
    </section>
  )
}