// extracted by mini-css-extract-plugin
export var active = "sponsor-module--active--l3WOD";
export var category = "sponsor-module--category--5KR2c";
export var categoryBtn = "sponsor-module--categoryBtn--2RWp2";
export var chevron = "sponsor-module--chevron--1WjIs";
export var dropOpen = "sponsor-module--dropOpen--Duyio";
export var fill = "sponsor-module--fill--z8IvH";
export var leaves = "sponsor-module--leaves--r2VQ1";
export var left = "sponsor-module--left--wcobA";
export var open = "sponsor-module--open--BaWKC";
export var padding = "sponsor-module--padding--EfmkH";
export var right = "sponsor-module--right--LiLCG";
export var section = "sponsor-module--section--u6rti";
export var show = "sponsor-module--show--cxXxn";
export var stick = "sponsor-module--stick--afbxN";