// extracted by mini-css-extract-plugin
export var body = "simpleText-module--body--zRKla";
export var container = "simpleText-module--container--066bX";
export var heading = "simpleText-module--heading--YwPve";
export var icon = "simpleText-module--icon--nwZ0N";
export var icons = "simpleText-module--icons--XexyP";
export var left = "simpleText-module--left--jWf4F";
export var right = "simpleText-module--right--y-eGX";
export var section = "simpleText-module--section--va9xj";
export var show = "simpleText-module--show--sx1t3";
export var wide = "simpleText-module--wide--Q7o4M";