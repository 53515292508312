import React, { useEffect } from 'react'
import * as styles from './growthRateSelector.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ growthRate, setGrowthRate, setData }) => {

  const rates = ['Slow', 'Medium', 'Fast'];

  useEffect(() => {
    setData();
  }, [growthRate])

  const handleClick = rate => {
    setGrowthRate(rates => {
      let copy = [...rates];
      if (copy.includes(rate))
        copy = copy.filter(growthRate => growthRate !== rate);
      else
        copy.push(rate);
      return copy;
    })
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}><Content text='Growth rate:' /></p>
      {rates.map(rate => (
        <button
          key={rate}
          className={styles.rate + ` ${growthRate.includes(rate) ? `${styles.active}` : ''}`}
          onClick={() => handleClick(rate)}
        ><Content text={rate} />
        </button>
      ))}
    </div>
  )
  
}