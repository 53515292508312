import React, { useEffect, useRef, useState } from 'react'
import * as styles from './communityService.module.scss'
import parse from 'html-react-parser'
import SharedTextButton from '../sharedTextButton'
import { Content, Leaf } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.container}>
        <img 
          src={block.image}
          className={styles.image + ` ${show ? `${styles.show}` : ''}`}
        />
        <div className={styles.content + ` ${show ? `${styles.show}` : ''}`}>
          <div className={styles.top}>
            <h2 className={styles.heading}>
              <Content text={block.blockTitle} />
            </h2>
            <div className={styles.topContent}>
              <Content text={block.topContent} />
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.bottomContent}>
              <Content text={block.bottomContent} />
            </div>
          </div>
        </div>
        <div className={styles.bottom} />
      </div>
    </section>
  )
}