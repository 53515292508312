import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Context } from '../../context'
import * as styles from './cart.module.scss'

export default ({}) => {
  const context = useContext(Context);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (context && context.checkout) {
      if (context.checkout.lineItems.length)
        setCount(context.checkout.lineItems.reduce(function(a, b) {
          return a + b.quantity
        }, 0))
    }
  }, [context && context.checkout])

  return (
    <Link to ='/checkout' className={styles.cartContainer}>
      <button className={styles.cart}>
        <img src='/shopping-cart.svg' className={styles.cartIcon} />
        <div className={styles.count}>
          <p>{count}</p>
        </div>
      </button>
    </Link>
  )
}