// extracted by mini-css-extract-plugin
export var button = "footerMultiForm-module--button--LurB1";
export var colOne = "footerMultiForm-module--colOne--oIgcx";
export var donationWidget = "footerMultiForm-module--donation-widget--xfhsF";
export var footerText = "footerMultiForm-module--footerText--zfWDp";
export var form = "footerMultiForm-module--form--6NCja";
export var formClose = "footerMultiForm-module--formClose--sICa1";
export var formContainer = "footerMultiForm-module--formContainer--Ifc5p";
export var formWrapper = "footerMultiForm-module--formWrapper--JIwJz";
export var heading = "footerMultiForm-module--heading--5HhPh";
export var icon = "footerMultiForm-module--icon--+mhgR";
export var image = "footerMultiForm-module--image--SnbTk";
export var input = "footerMultiForm-module--input--TRU5+";
export var intro = "footerMultiForm-module--intro--D09Ab";
export var label = "footerMultiForm-module--label--NnFq2";
export var left = "footerMultiForm-module--left--E7A4b";
export var loader = "footerMultiForm-module--loader--ouoA3";
export var message = "footerMultiForm-module--message--XjKdA";
export var noPadding = "footerMultiForm-module--noPadding--llwe3";
export var pageHeading = "footerMultiForm-module--pageHeading--nuKD6";
export var right = "footerMultiForm-module--right--S9nG3";
export var scroll = "footerMultiForm-module--scroll---JnVn";
export var section = "footerMultiForm-module--section--i6a4N";
export var show = "footerMultiForm-module--show--l2Co8";