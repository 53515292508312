// extracted by mini-css-extract-plugin
export var body = "imageText-module--body--wNd-d";
export var container = "imageText-module--container--1gYmw";
export var heading = "imageText-module--heading--DCTon";
export var icon = "imageText-module--icon--MBUi3";
export var icons = "imageText-module--icons--O-EbV";
export var image = "imageText-module--image--0GXVU";
export var left = "imageText-module--left--Yd4wv";
export var right = "imageText-module--right--OqjP6";
export var section = "imageText-module--section--8cF7R";
export var show = "imageText-module--show--qlwcA";
export var wide = "imageText-module--wide--Pw7Wd";