import React, { useState, useEffect, useRef } from 'react'
import * as styles from './stories.module.scss'
import { Content } from '../../../../utilities'
import { Link } from 'gatsby';
import * as scrollFn from 'scroll'

export default ({ block, scroll, stories }) => {
  const ref = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const [show, setShow] = useState(false);
  const [moves, setMoves] = useState(3);
  const [disable, setDisable] = useState(false);
  const [width, setWidth] = useState(null);
  const [filteredStories, setFilteredStories] = useState([]);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  const colors = ['#32B44A', '#FFA225', '#067BC2'];

  const getColor = i => {
    if (i <= 2)
      return colors[i];
    else if (i % 3 === 0)
      return colors[0];
    else if ((i - 1) % 3 === 0)
      return colors[1];
    else
      return colors[2];
  }

  useEffect(() => {
    const storyArray = [];
    block.stories.forEach(selected => {
      const name = selected.story.split('src/stories/')[1];
      const filtered = stories.find(story => {
        if (story.file.split('src/stories/')[1] === name) {
          return story;
        }
      })
      if (filtered) {
        storyArray.push(filtered);
      }
    })
    setFilteredStories(storyArray);
  }, [])

  useEffect(() => {
    const reset = () => {
      if (ref.current) {
        ref.current.scrollLeft = 0;
        if (typeof window !== 'undefined' && window.innerWidth > 767 && block.stories.length <= 3) {
          setShowLeft(false);
          setShowRight(false);
        }
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
          setMoves(3);
          setShowLeft(false);
          setShowRight(true);
        } else {
          setMoves(1);
          setShowLeft(false);
          setShowRight(true);
        }
      }
    }
    reset();
    // window.addEventListener('resize', reset)

    // return (() => {
    //   window.removeEventListener('resize', reset)
    // })
  }, [])

  const moveLeft = () => {
    setDisable(true);
    let distance = 0;
    if (window.innerWidth > 767)
      distance = window.innerWidth / 100 * 30.5;
    else
      distance = window.innerWidth;
    if (ref.current) {
      setShowRight(true);
      scrollFn.left(ref.current, ref.current.scrollLeft - distance)
      if (window.innerWidth > 767 && moves === 4) {
        setShowLeft(false);
      } else if (window.innerWidth <= 767 && moves === 2) {
        setShowLeft(false);
      }
      setMoves(moves - 1);
    }
    setTimeout(() => {
      setDisable(false);
    }, 300)
  }

  const moveRight = () => {
    setDisable(true);
    let distance = 0;
    if (window.innerWidth > 767)
      distance = window.innerWidth / 100 * 30.5;
    else
      distance = window.innerWidth;
    if (ref.current) {
      setShowLeft(true);
      scrollFn.left(ref.current, ref.current.scrollLeft + distance)
      setMoves(moves + 1);
      if (moves === block.stories.length - 1)
        setShowRight(false);
    }
    setTimeout(() => {
      setDisable(false);
    }, 300)
  }

  return (
    <section className={styles.section} id={block.template.replace('main-', '')}>
      <h2 className={styles.heading}>
        <Content text={block.blockTitle} />
      </h2>
      <div 
        className={styles.stories + ` ${show ? `${styles.show}` : ''}`}
        ref={ref}
      >
        {filteredStories.length && filteredStories.map((story, i) => (
          <Link
            to={`/${story.slug}`} 
            key={i} 
            className={styles.story}
            style={{backgroundColor: getColor(i)}}
          >
            <div className={styles.container}>
              <p className={styles.title}>
                <Content text={story.data.title} />
              </p>
              <p className={styles.description}>
                <Content text={story.data.description} />
              </p>
            </div>
          </Link>
        ))}
        <div className={styles.padding} />
      </div>
      <div className={styles.buttons}>
        <button 
          className={styles.left + ` ${!showLeft ? `${styles.hide}` :''}` + ` ${disable ? `${styles.disable}` : ''}`}
          onClick={moveLeft}><img src='/button.svg' className={styles.leftIcon} />
        </button>
        <button 
          className={styles.right + ` ${!showRight ? `${styles.hide}` :''}` + ` ${disable ? `${styles.disable}` : ''}`}
          onClick={moveRight}><img src='/button.svg' className={styles.rightIcon} />
        </button>
      </div>
      <div className={styles.background} />
    </section>
  )
}
