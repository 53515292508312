import React from 'react'
import * as styles from './social.module.scss'

export default ({ block, show }) => {

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      {block.socialLinks.map(link => (
        <a key={link.url} href={link.url} className={styles.link} target='_blank' rel='noreferrer'>
          <img src={link.icon} className={styles.icon} />
        </a>
      ))}
    </section>
  )
}