import React, { useEffect, useRef, useState } from 'react'
import * as styles from './footerMultiForm.module.scss'
import parse from 'html-react-parser'
import { Content, checkEmail, checkValidInputs, checkZip, handleChange, handleOptionClick } from '../../../../utilities'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ block, scroll, category }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [currentPage, setCurrentPage] = useState(-1);
  const [formShow, setFormShow] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  const formPages = block.formPages ?? [];

  useEffect(() => {
    const inputs = {};
    if (formPages.length) {
      formPages.map(page => page.inputs.forEach(input => {
        inputs[input] = '';
      }))
    }
    setFormValues(inputs);
  }, [category])

  const reset = () => {
    setFormShow(false);
    setCurrentPage(-1);
  }

  useEffect(() => {
    reset();
  }, [category])

  const isObject = label => {
    return typeof formValues[label] === 'object' && formValues[label] !== null
  }

  const checkObject = object => {
    let hasChecked = false;
    for (let [key, value] of Object.entries(object)) {
      if (value)
        hasChecked = true;
    }
    return hasChecked;
  }

  const checkIfValid = label => {
    return (
      checkValidInputs(label, formValues, checkEmail, checkZip, isObject, checkObject)
    )
  }

  const handleInputChange = e => {
    handleChange(e, setFormValues);
  }

  const handleIntroClick = () => {
    setCurrentPage(0);
    setFormShow(true);
  }

  const handleButtonClick = () => {
    if (formPages.length && currentPage < formPages.length - 1) {
      // setFormShow(false);
      setCurrentPage(currentPage + 1);
      // setTimeout(() => {
      //   setFormShow(true);
      // }, 100);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    formPages.map(page => page.inputs.forEach(input => {
      if (!checkIfValid(input)) {
        setShowErrorMessage(true);
        error = true;
      }
    }))
    if (!error) {
      setShowErrorMessage(false);
      setLoader(true);
      fetch('/.netlify/functions/contact', {
        method: 'POST',
        body: JSON.stringify({
          ...formValues,
          emails: block.emails,
          form: block.formTitle
        })
      })
      .then(response => {
        setLoader(false);
        setSubmitted(true);
        const inputs = {};
        if (block.inputs) {
          const inputs = {};
          if (formPages.length) {
            formPages.map(page => page.inputs.forEach(input => {
              inputs[input] = '';
            }))
          }
          setFormValues(inputs);
        }
      })
    }
  }

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}` + ` ${block.submitTo && formShow ? `${styles.scroll}` : ''}`} ref={ref}>
      <div className={styles.left}>
        {!(currentPage >= 0) &&
        <div className={styles.intro}>
          <div className={styles.heading}>
            <Content text={block.formIntroHeading} />
          </div>
          <button
            className={styles.button}
            onClick={handleIntroClick}
          >
            {block.formIntroButtonText}<span><img src='/button.svg' className={styles.icon} /></span>
          </button>
        </div>}
        {formPages.length > 0 &&
        <form className={styles.form + ` ${formShow ? `${styles.show}` : ''}`} onSubmit={handleSubmit}>
          <h2 className={styles.pageHeading}>
            <Content text={formPages[currentPage] ? formPages[currentPage].formPageHeading : ''} />
          </h2>
          {formPages[currentPage] && formPages[currentPage].inputs.map(input => (
            <div key={input} className={styles.colOne}>
              <label className={styles.label} htmlFor={input}>
                <Content text={input} />
              </label>
              <input
                className={styles.input}
                value={formValues[input]}
                name={input}
                id={input}
                onChange={handleInputChange}
                type={input.includes('Phone') ? 'number' : 'text'}
              />
            </div>
          ))}
          <button
            type={currentPage === formPages.length - 1 ? 'submit' : 'button'}
            className={styles.button + ` ${formPages[currentPage] && !formPages[currentPage].buttonText ? 
              `${styles.noPadding}` : ''}`}
            onClick={handleButtonClick}
          >
            <Content text={formPages[currentPage] ? formPages[currentPage].buttonText : ''} /><span><img src='/button.svg' className={styles.icon} /></span>
          </button>
          {loader &&
          <div className={styles.loader}>
            <Loader
              type="TailSpin"
              color="#0C3507"
              height={50}
              width={50}
            />
          </div>}
          {showErrorMessage &&
          <p className={styles.message}>
            <Content text='Please enter valid input' />.
          </p>}
          {submitted &&
          <p className={styles.message}>
            <Content text={`Thanks, we'll be in touch!`} />.
          </p>}
        </form>}
        {block.submitTo && formShow &&
        <div className={styles.formContainer + ` ${formShow ? `${styles.show}` : ''}`}>
          <div className={styles.formWrapper}>
            <iframe
              height="349"
              name="donorbox"
              scrolling="yes"
              src={block.submitTo}
              width="560">
            </iframe>
          </div>
          <button
            style={{backgroundImage: 'url(/x-green.svg)'}}
            className={styles.formClose}
            onClick={reset}
          />
        
        </div>}
        {formPages && formPages[currentPage] && formPages[currentPage].formFooterText &&
        <div className={styles.footerText}>
          <Content text={formPages[currentPage].formFooterText} />
        </div>}
      </div>
      <div className={styles.right}>
        <img src={block.formImage} className={styles.image} />
      </div>
    </section>
  )
}