import React, { useState, useRef, useEffect, useContext } from 'react'
import { Context } from '../../../../context'
import * as styles from './blog.module.scss'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import { filteredArticles, SearchBox } from './blogSearch'
import { compareDate, Content, FormatDate } from '../../../../utilities'

export default ({ block, articles, scroll }) => {
  const context = useContext(Context);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState('All');
  const [articleArray, setArticleArray] = useState(articles);
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100)
  }, [])

  useEffect(() => {
    const language = context && context.language ? context.language : 'en';
    const setArticles = async () => {
      setArticleArray(await filteredArticles(search, articles, active, language));
    }
    setArticles();
  }, [search, active])

  const featured = articles.find(article => article.file.split('/articles')[1] === block.featured.split('/articles')[1]);

  const button = {
    url: featured.slug ? `/${featured.slug}` : '/happenings',
    label: "Read more",
    linkType: "url",
    color: "green"
  };

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.top} >
        <img 
          src={featured && featured.data.image} 
          className={styles.image + ` ${show ? `${styles.show}` : ''}`} 
        />
        <div 
          className={styles.background + ` ${show ? `${styles.show}` : ''}`} 
        >
          {featured.data.date &&
          <p className={styles.date}><Content text='Posted' /> <span><FormatDate date={featured.data.date} /></span></p>}
          {featured.data.title &&
          <div className={styles.title}>
            {featured.data.title > 148 ? 
            <Content text={featured.data.title.slice(0,148) + '...'} /> : 
            <Content text={featured.data.title} />}
          </div>}
          {featured.data.body &&
          <div className={styles.body}>
            {featured.data.body.length > 300 ? 
            <Content text={featured.data.body.slice(0,300) + '...'} /> : 
            <Content text={featured.data.body} />}
          </div>}
        </div>
        <div className={styles.buttonContainer + ` ${show ? `${styles.show}` : ''}`}>
          <Link to={`/${featured.slug}`} className={styles.button}>
            <Content text='Read more' />
            <span><img src='/button.svg' className={styles.icon} /></span>
          </Link>
        </div>
      </div>
      <div className={styles.row} id="articles">
        <div className={styles.categories}>
          <button
            key={'All'}
            className={styles.category + ` ${active === 'All' ? `${styles.active}` : ''}`}
            onClick={() => setActive('All')}
          >
            <Content text='All' />
          </button>
          {block.categories.map(category => (
            <button
              key={category}
              className={styles.category + ` ${active === category ? `${styles.active}` : ''}`}
              onClick={() => setActive(category)}
            >
                <Content text={category} />
            </button>
          ))}
        </div>
        <div className={styles.searchContainer}>
          <SearchBox search={search} setSearch={setSearch} />
        </div>
      </div>
      
      <div className={styles.articles}>
        {articleArray.sort(compareDate).map(({slug, data}) => (
          <Link to={`/${slug}`} className={styles.article} key={slug}>
            <img src={data.image} className={styles.image} />
            <p className={styles.date}><Content text='Posted' /> <span><FormatDate date={data.date} /></span></p>
            <p className={styles.title}>
              {data.title > 50 ? 
              <Content text={data.title.slice(0,50) + '...'} /> : 
              <Content text={data.title} />}
            </p>
            <div className={styles.body}>
              {data.body.length > 150 ? 
              <Content text={data.body.slice(0,150) + '...'} /> : 
              <Content text={data.body} />}
            </div>
          </Link>
        ))}
        {articleArray.length === 0 ? <p className={styles.none}><Content text='No results' /></p> : null}
      </div>
    </section>
  )
}