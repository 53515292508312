import React, { useContext } from 'react'
import { Context } from '../../../../context'
import * as styles from './location.module.scss'

export default ({ block }) => {
  const context = useContext(Context);

  return (
    <section className={styles.section}>
      <p className={styles.location}>{context && context.location}</p>
      <img src='/navigation.svg' className={styles.icon} />
    </section>
  )
}
