import React, { useEffect, useRef, useState, useContext } from 'react'
import * as styles from './footerImage.module.scss'
import parse from 'html-react-parser'
import SharedTextButton from '../sharedTextButton'
import { Content, Leaf, SlideoutForm } from '../../../../utilities'
import { Context } from '../../../../context'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState(null);
  const [season, setSeason] = useState('summer');
  const context = useContext(Context);
  const [mobile, setMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 767 ? true :
    false
  )
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
      setPosition(ref.current.getBoundingClientRect().top)
    }
  }, [scroll])

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 767)
        setMobile(false);
      else setMobile(true);
    }

    window.addEventListener('resize', resize);

    return (() => {
      window.removeEventListener('resize', resize);
    })
  })

  useEffect(() => {
    if (context && context.season) {
      setSeason(context.season);
    }
  }, [context && context.season])

  return (
    <section
      className={styles.section + ` ${block.text.length > 150 ? `${styles.long}` : ''}`}
      ref={ref}
      id={block.template && block.template.replace('main-', '')}
    >
      {block.leafOne && block.leafOne.fall && block.leafOne.summer &&
       <Leaf 
        width={mobile ? '20vw' : '10vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafOne.fall : block.leafOne.summer}
        top={mobile ? 130 : 0}
        left={block.imageAlignment === 'left' ? null : -2}
        right={block.imageAlignment === 'right' ? null : -2}
        speed={2}
        direction={'down'}
      />}
       {block.leafTwo && block.leafTwo.fall && block.leafTwo.summer &&
      <Leaf 
        width={mobile ? '38vw' : '19vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafTwo.fall : block.leafTwo.summer}
        top={mobile ? 80 : 5}
        left={block.imageAlignment === 'left' ? null : (mobile ? 70 : 13)}
        right={block.imageAlignment === 'right' ? null : (mobile ? 70 : 13)}
        direction={'up'}
      />}
       {block.leafThree && block.leafThree.fall && block.leafThree.summer &&
      <Leaf 
        width={mobile ? '28vw' : '18vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafThree.fall : block.leafThree.summer}
        top={mobile ? 100 : 30}
        left={block.imageAlignment === 'left' ? null : -3}
        right={block.imageAlignment === 'right' ? null : -3}
        direction={'down'}
        zIndex={2}
        speed={7}
      />}
      <div className={styles.container}>
        <img 
          src={block.image}
          className={styles.image + ` ${styles[block.imageAlignment]}` + ` ${show ? `${styles.show}` : ''}`}
        />
        <div className={styles.content + ` ${styles[block.imageAlignment]}`  + ` ${show ? `${styles.show}` : ''}`}>
          <div className={styles.text}>
            <Content text={block.text} />
          </div>
          <div className={styles.buttonContainer}>
            <SharedTextButton button={block.button} onClick={() => setFormOpen(!formOpen)} />
          </div>
        </div>
        <div className={styles.bottom} />
      </div>
      {block.inputs && block.inputs.length > 0 &&
      <SlideoutForm block={block} formOpen={formOpen} setFormOpen={setFormOpen} />}
    </section>
  )
}