// extracted by mini-css-extract-plugin
export var body = "whyTrees-module--body--9cDiE";
export var bottom = "whyTrees-module--bottom--0aQxA";
export var description = "whyTrees-module--description--dqn0Z";
export var heading = "whyTrees-module--heading--+q2E8";
export var icon = "whyTrees-module--icon--mr6-b";
export var iconContainer = "whyTrees-module--iconContainer--zKc0J";
export var image = "whyTrees-module--image--Sc6+e";
export var listItem = "whyTrees-module--listItem--PS4B1";
export var right = "whyTrees-module--right--jq1Vk";
export var section = "whyTrees-module--section--8RuSb";
export var show = "whyTrees-module--show--sNwF4";
export var stat = "whyTrees-module--stat--u+tys";
export var text = "whyTrees-module--text--wTe6u";
export var top = "whyTrees-module--top--yGZ3G";