export default date => {
  const dateObject = new Date(date);
  let getHour = dateObject.getHours();
  let hour = 0;
  let minutes = dateObject.getMinutes().toString();
  let amPm = '';

  if (getHour === 0) {
    hour = 12;
    amPm = 'AM';
  } else if (getHour > 12) {
    hour = getHour - 12;
    amPm = 'PM';
  } else if (getHour < 12) {
    hour = getHour;
    amPm = 'AM';
  } else {
    hour = getHour;
    amPm = 'PM';
  }

  if (minutes === '0') {
    minutes = '00'
  }
  return `${hour}:${minutes} ${amPm}`
}