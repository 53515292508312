import React, { useEffect } from 'react'
import * as styles from './lightSelector.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, light, setLight, setData }) => {

  useEffect(() => {
    setData();
  }, [light])

  const handleClick = type => {
    setLight(types => {
      let copy = [...types];
      if (copy.includes(type))
        copy = copy.filter(lightType => lightType !== type);
      else
        copy.push(type);
      return copy;
    })
  }

  return (
    <div className={styles.icons}>
      <button 
        className={styles.iconContainer + ` ${light.includes('Full Sun') ? `${styles.active}` : ''}`}
        onClick={() => handleClick('Full Sun')}>
        <img src={block.sunIcon} className={styles.icon} />
        <p className={styles.iconText}><Content text='Sun' /></p>
      </button>
      <button
        className={styles.iconContainer + ` ${light.includes('Partial sun') ? `${styles.active}` : ''}`}
        onClick={() => handleClick('Partial sun')}>
        <img src={block.partialSunIcon} className={styles.icon} />
        <p className={styles.iconText}><Content text='Partial sun' /></p>
      </button>
      <button 
        className={styles.iconContainer + ` ${light.includes('Shade') ? `${styles.active}` : ''}`}
        onClick={() => handleClick('Shade')}>
        <img src={block.shadeIcon} className={styles.icon} />
        <p className={styles.iconText}><Content text='Shade' /></p>
      </button>
    </div>
  )
}