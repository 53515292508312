  export default (a, b) => {
    const timeOne = a.data.date;
    const timeTwo = b.data.date;
    let comparison = 0;
    if (new Date(timeOne) > new Date(timeTwo)) {
      comparison = 1;
    } else if (new Date(timeOne) < new Date(timeTwo)) {
      comparison = -1;
    }
    return comparison;
  }