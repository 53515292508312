import React, { useState, useEffect, useRef } from 'react'
import * as styles from './sponsorContent.module.scss'
import { BodyText, BodyData, TopTextImage } from './components'
import AvailableSpecies from '../availableSpecies'
import FooterMultiForm from '../footerMultiForm'
import { Content } from '../../../../utilities'

export default ({ block, scroll, category, trees }) => {

  return (
    <section className={styles.section}>
      <TopTextImage block={block} />
      <div className={styles.main}>
        <div className={styles.bodyLeft}>
          <h2 className={styles.bodyHeading}>
            <Content text={block.bodyHeading} />
          </h2>
          <img 
            src={block.secondImage} 
            className={styles.secondImage + ` ${block.secondImage === '/adopt-tree-bottom.png' ? `${styles.contain}` :
            block.secondImage === '/evergreen-bottom.png' ? `${styles.evergreen}` : ''}`}
          />
          {block.footerText && (
            <div className={styles.footerText}>
              <Content text={block.footerText} />
            </div>
          )}
          {block.contact && (
            <div className={styles.contact}>
              <p className={styles.contactLabel}><Content text='For more information contact:' /></p>
              <div className={styles.contactText}>
                <Content text={block.contact} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.bodyRight}>
          {block.hasData ? (
            <BodyData block={block} scroll={scroll}  />
          ): <BodyText block={block} scroll={scroll} />}
        </div>
      </div>
      {block.hasSpecies &&
      <AvailableSpecies block={block} scroll={scroll} trees={trees} category={category} moveStart={4} padding={true}/>}
      {block.map &&
      <h3 className={styles.locations}><Content text='Available locations' /></h3>}
      {block.map &&
      <div className={styles.mapContainer}>
        <div className={styles.mapWrapper}>
          <iframe src={block.map} width="560" height="349"></iframe>
        </div>
      </div>}
      {block.hasForm &&
      <FooterMultiForm block={block} scroll={scroll} category={category} />}
    </section>
  )
}