// extracted by mini-css-extract-plugin
export var background = "stories-module--background--jgve1";
export var buttons = "stories-module--buttons--QGNG5";
export var container = "stories-module--container--4EXRa";
export var description = "stories-module--description--VM0k5";
export var disable = "stories-module--disable--aaM05";
export var heading = "stories-module--heading--E7Mcs";
export var hide = "stories-module--hide--JJoqT";
export var left = "stories-module--left--zDrI0";
export var leftIcon = "stories-module--leftIcon--iDbyn";
export var padding = "stories-module--padding--G6BKp";
export var right = "stories-module--right--gsYdi";
export var rightIcon = "stories-module--rightIcon--fthhb";
export var section = "stories-module--section--qd2WD";
export var show = "stories-module--show--2DxqJ";
export var stories = "stories-module--stories--yk+Tu";
export var story = "stories-module--story--3O9s4";
export var title = "stories-module--title--WnBKm";