import React, { useEffect, useRef, useState } from 'react'
import * as styles from './storiesFooter.module.scss'
import parse from 'html-react-parser'
import SharedTextButton from '../sharedTextButton'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.container}>
        <img 
          src={block.image}
          className={styles.image + ` ${styles[block.imageAlignment]}` + ` ${show ? `${styles.show}` : ''}`}
        />
        <div className={styles.content + ` ${styles[block.imageAlignment]}`  + ` ${show ? `${styles.show}` : ''}`}>
          <div className={styles.text}>
            <Content text={block.text} />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.buttonContainer}>
            <SharedTextButton button={block.button} />
          </div>
        </div>
      </div>
    </section>
  )
}