import React, { useEffect, useState, useRef } from 'react'
import * as styles from './heightSlider.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ minHeight, setMinHeight, maxHeight, setMaxHeight, setData }) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const handleChange = e => {
    const input = e.target;
    if (input.name === 'min' && +input.value <= (maxHeight - 10)) {
      setMinHeight(+e.target.value);
    }
    else if (input.name === 'max' && +input.value >= (minHeight + 10)) {
      setMaxHeight(+e.target.value);
    }
  }

  useEffect(() => {
    setData();
  }, [minHeight, maxHeight])

  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    }

    resize();

    window.addEventListener('resize', resize);

    return (() => {
      window.removeEventListener('resize', resize);
    })
  }, [])

  return (
    <div className={styles.container}>
      <p className={styles.slideLabel}><Content text='Mature height:' /></p>
      <div className={styles.slideContainer}>
        <div className={styles.colorContainer}>
          <div className={styles.color}></div>
        </div>
        <input 
          className={styles.slider}
          value={minHeight}
          type='range'
          name='min'
          min={0} 
          max={100} 
          step={1} 
          onChange={handleChange}
          ref={ref}
        />
      </div>
      <div
        className={styles.between}
        style={{
          width: width / 100 * (maxHeight - minHeight),
          left: width / 100 * (minHeight)
        }}
      />
      <div className={styles.slideContainerTwo}>
        <div className={styles.colorContainer}>
          <div className={styles.color}></div>
        </div>
        <input 
          className={styles.slider}
          value={maxHeight}
          type='range'
          name='max'
          min={0} 
          max={100} 
          step={1} 
          onChange={handleChange}
        />
      </div>
      <p className={styles.sliderValue}>{minHeight}-{maxHeight}ft</p>
    </div>
  )
  
}