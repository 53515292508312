import React, { useEffect, useState, useRef } from 'react'
import * as styles from './bodyText.module.scss'
import parse from 'html-react-parser'
import { Content } from '../../../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.list + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <Content text={block.bodyText} />
      {block.icons.length > 0 && block.icons.map(({icon}, i) => (
        <img src={icon} className={styles.icon} style={{transitionDelay: `.${i + 2}s`}}/>
      ))}
    </div>
  )
}