import React, { useState } from 'react'
import * as styles from './searchBox.module.scss'

export default ({ search, setSearch }) => {
  const [input, setInput] = useState('');

  const handleChange = e => {
    setInput(e.target.value);
    if (!e.target.value) {
      setSearch('');
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    setSearch(input)
  }

  return (
    <form onSubmit={handleSubmit}>
      <input className={styles.searchBox} value={input} onChange={handleChange} />
    </form>
  )
}