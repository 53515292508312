// extracted by mini-css-extract-plugin
export var background = "vomo-module--background--rSkEj";
export var bottom = "vomo-module--bottom--DzSAO";
export var buttons = "vomo-module--buttons--hJhz5";
export var container = "vomo-module--container--y0hjm";
export var count = "vomo-module--count--dFSVR";
export var date = "vomo-module--date--089mM";
export var day = "vomo-module--day--vutaq";
export var description = "vomo-module--description--9yNBL";
export var disable = "vomo-module--disable--bXm0v";
export var event = "vomo-module--event--DG3La";
export var events = "vomo-module--events--lAn21";
export var footerText = "vomo-module--footerText--uD2U5";
export var heading = "vomo-module--heading--UHvcb";
export var hide = "vomo-module--hide--UAMn6";
export var left = "vomo-module--left--6J63d";
export var leftIcon = "vomo-module--leftIcon--ZRoJf";
export var location = "vomo-module--location--yImtb";
export var noPadding = "vomo-module--noPadding--IiyVB";
export var padding = "vomo-module--padding--zI2xG";
export var right = "vomo-module--right--wg11X";
export var rightIcon = "vomo-module--rightIcon--U+Ph5";
export var section = "vomo-module--section--tObFp";
export var show = "vomo-module--show--UaZLo";
export var title = "vomo-module--title--JLsd+";
export var weekday = "vomo-module--weekday--kl4m-";