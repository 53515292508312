// extracted by mini-css-extract-plugin
export var bottom = "communityService-module--bottom--vt03V";
export var bottomContainer = "communityService-module--bottomContainer--0+sLD";
export var bottomContent = "communityService-module--bottomContent--UhCSs";
export var container = "communityService-module--container--kPA3S";
export var content = "communityService-module--content--HtMQn";
export var heading = "communityService-module--heading--ct8xv";
export var image = "communityService-module--image--iiFG8";
export var section = "communityService-module--section--+AKaQ";
export var show = "communityService-module--show--7sBb5";
export var top = "communityService-module--top--xuKzv";
export var topContent = "communityService-module--topContent--pFQtv";