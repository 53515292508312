import React, { useContext } from 'react'
import { Context } from '../../../../context'
import * as styles from './languages.module.scss'
import Languages from '../../../../settings/languages.json'

export default () => { 
  const context = useContext(Context);

  return (
    <section className={styles.section + ` ${context && context.languagesOpen ? `${styles.open}` : ''}`}>
      {Languages.languages.map(language => (
        <div key={language.code} className={styles.container} onMouseDown={() => {
          if (context) {
            context.setLanguage(language.code);
            context.setLanguagesOpen(false);
          }
        }}>
          <div className={styles.imgContainer}>
            <img src={language.flag} className={styles.flag} />
          </div>
          <p className={styles.language}>{language.language}</p>
        </div>
      ))}
    </section>
  )
}