import React, { useEffect, useState } from 'react'
import * as styles from './goodForSelector.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, goodFor, setGoodFor, setData }) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleClick = type => {
    setGoodFor(types => {
      const copy = [...types];
      copy.push(type);
      return copy;
    })
  }

  const handleRemove = treeType => {
    setGoodFor(types => {
      const copy = types.filter(type => type !== treeType);
      return copy;
    })
  }

  useEffect(() => {
    setData();
  }, [goodFor])

  return (
    <div className={styles.container}>
      <div onClick={() => setSelectOpen(!selectOpen)} className={styles.select + ` ${selectOpen === true ? `${styles.open}` : ""}`}>
        {!selectOpen &&
        <img src={'/chevron-right.svg'} className={styles.arrow} />}
        {selectOpen &&
        <img src={'/chevron-right-white.svg'} className={styles.arrow} />}
        <p className={styles.placeholder + ` ${selectOpen ? `${styles.open}` : ''}`}><Content text='Good for:' /></p>
        {block.goodFor.filter(type => !goodFor.includes(type)).map(type => (
          <button
            key={type}
            className={styles.type + ` ${selectOpen ? `${styles.open}` : ''}`}
            onClick={() => handleClick(type)}
          ><Content text={type} /></button>
        ))}
      </div>
      {goodFor.length > 0 ? goodFor.map((type, i) => (
        <div key={i} className={styles.typeFilter}>
          <button 
            className={styles.remove}
            onClick={() => handleRemove(type)}
            style={{backgroundImage: 'url(/x.svg)'}}
          />
          <Content text={type} />
        </div>
      )) : null}
    </div>
  )
}