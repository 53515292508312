import React, { useEffect, useState, useContext } from 'react'
import * as styles from './zoneSelector.module.scss'
import { Context } from '../../../../../../context'
import { Content, getTranslation } from '../../../../../../utilities'

export default ({ zone, setZone, setData }) => {
  const [input, setInput] = useState('');
  const context = useContext(Context);
  const [placeholder, setPlaceholder] = useState('Zip/Zone');

  const parseResult = result => {
    let parsed = '';
    for (let i = 0; i < result.length; i++) {
      if (!isNaN(result[i]))
        parsed += result[i]
    }
    return parsed;
  }

  useEffect(() => {
    const translateText = async () => {
      if (context && context.language !== 'en') {
        const result = await getTranslation('Zip/Zone', context.language);
        if (result['data'][0]) {
          setPlaceholder(result['data'][0]);
        }
      } else setPlaceholder('Zip/Zone')
    }
    translateText();
  }, [context && context.language])

  const handleChange = e => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 5) {
      setInput(value);
      if (value.length === 5)
        fetch(`https://phzmapi.org/${value}.json`)
        .then(response => response.json())
        .then(res => {
          if (res.zone)
            setZone(parseResult(res.zone));
        })
        .catch(error => console.log(error))
      else {
        setZone(value);
      }
    }
    // else if (!value)
    //     setZone('');
  }

  useEffect(() => {
    setData();
  }, [zone])

  return (
    <input 
      className={styles.input}
      placeholder={`${placeholder}:`}
      value={input}
      onChange={handleChange}
    />
  )
}