import React from 'react'
import { Link } from 'gatsby'

type props = {
  children?: React.ReactNode | [React.ReactNode],
  className?: string,
  activeClassName?: string,
  onClick?: any,
  link: {
    label: string,
    page: string,
    partner: string
  },
  customLink?: string
}

export default ({ children = [], className, activeClassName, link, customLink, onClick = () => {} }: props) => {
  
  const directory = 'src/pages';

  return (
    <Link to={customLink ? customLink : link.page.replace(directory, '').replace('.json', '').replace('index', '')} className={className} activeClassName={activeClassName} onClick={onClick}>
      {children.length ? children : link.label}
    </Link>
  )
}