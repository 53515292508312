import React from 'react'
import * as styles from './educationContent.module.scss'
import { BottomIconList, BottomRichText, Heading, TopIconList, TopImage, TopRichText, Video, WistiaVideo } from './components'
import Eventbrite from '../eventbrite'
import { Content } from '../../../../utilities'
import FooterForm from '../footerForm'

export default ({ block, scroll, category, subcategory }) => {
  
  return (
    <section className={styles.section}>
      <TopImage image={block.firstImage} />
      <Heading heading={block.blockTitle} subheading={block.subheading} scroll={scroll} />
      <div className={styles.main}>
        <div className={styles.left}>
          {block.secondVideo ? (
            <WistiaVideo id={block.videoId} scroll={scroll} />
          ) :
          <img src={block.secondImage} className={styles.secondImage} />}
          {block.contact && (
            <div className={styles.contact}>
              <p className={styles.contactLabel}><Content text='For more information contact:' /></p>
              <div className={styles.contactText}>
                <Content text={block.contact} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.right}>
          {block.hasTopContent ? (
          <div className={styles.top}>
            {block.topContent.isIconList ? (
              <TopIconList items={block.topContent.iconList} scroll={scroll} />
            ): <TopRichText block={block.topContent} scroll={scroll} />}
          </div>) : <div className={styles.fill} />}
          <div className={styles.bottom}>
            {block.bottomContent && block.bottomContent.isIconList ? (
              <BottomIconList items={block.bottomContent.iconList} scroll={scroll} />
            ): <BottomRichText block={block.bottomContent} scroll={scroll} />}
          </div>
        </div>
      </div>
      {block.hasEvents &&
      <div className={styles.eventsContainer}>
        <Eventbrite block={block} scroll={scroll} category={category} subcategory={subcategory} moveStart={2} padding={true} />
      </div>}
      {block.videoUrl && 
      <Video url={block.videoUrl} scroll={scroll} />}
      {block.hasForm &&
      <FooterForm block={block} scroll={scroll} />}
    </section>
  )
}

{/* <p>For more information:</p><p>Kaitlyn Beard, Program Associate</p><p>kaitlyn@upwithtrees.org</p><p>918-610-8733</p> */}