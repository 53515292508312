import React, { useState, useEffect } from 'react'
import * as styles from './typeSelector.module.scss'
import { Content } from '../../../../../../utilities'


export default ({ block, types, setTypes, setData }) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleClick = treeType => {
    setTypes(types => {
      const copy = [...types];
      copy.push(treeType);
      return copy;
    })
  }

  const handleRemove = treeType => {
    setTypes(types => {
      const copy = types.filter(type => type !== treeType);
      return copy;
    })
  }

  useEffect(() => {
    setData();
  }, [types])

  return (
    <div className={styles.container}>
      <div onClick={() => setSelectOpen(!selectOpen)} className={styles.select + ` ${selectOpen === true ? `${styles.open}` : ""}`}>
        {!selectOpen &&
        <img src={'/chevron-right.svg'} className={styles.arrow} />}
        {selectOpen &&
        <img src={'/chevron-right-white.svg'} className={styles.arrow} />}
        <p className={styles.placeholder + ` ${selectOpen ? `${styles.open}` : ''}`}><Content text='Tree types:' /></p>
        {block.treeTypes.filter(treeType => !types.includes(treeType)).map(treeType => (
          <button
            key={treeType}
            className={styles.type + ` ${selectOpen ? `${styles.open}` : ''}`}
            onClick={() => handleClick(treeType)}
          ><Content text={treeType} /></button>
        ))}
      </div>
      {types.length > 0 ? types.map((treeType, i) => (
        <div key={i} className={styles.typeFilter}>
          <button 
            className={styles.remove}
            onClick={() => handleRemove(treeType)}
            style={{backgroundImage: 'url(/x.svg)'}}
          />
          <Content text={treeType} />
        </div>
      )) : null}
    </div>
  )
}