import React, { useState, useEffect, useRef } from "react";
import * as styles from "./ourMission.module.scss";
import SharedTextButton from "../sharedTextButton";
import { Content, SlideoutForm } from "../../../../utilities";

export default ({ block, scroll }) => {
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    if (
      scroll &&
      ref.current &&
      ref.current.getBoundingClientRect().top < window.innerHeight * 0.5
    ) {
      setShow(true);
    }
  }, [scroll]);

  const handleClick = (i) => {
    setShow(false);
    setCurrent(i);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };

  return (
    <section
      className={styles.section}
      id={block.template.replace("main-", "")}
    >
      <div className={styles.heading}>
        <Content text={block.blockTitle} />
      </div>
      <div className={styles.container}>
        <div className={styles.values}>
          <p className={styles.valuesHeading}>
            <Content text={block.valuesHeading} />
          </p>
          {block.values.map((value, i) => (
            <button
              key={value.label}
              className={
                styles.value + ` ${current === i ? `${styles.current}` : ""}`
              }
              onClick={() => handleClick(i)}
            >
              <Content text={value.label} />
            </button>
          ))}
        </div>
        <div
          className={styles.middle + ` ${show ? `${styles.show}` : ""}`}
          ref={ref}
        >
          {block.values[current] && block.values[current].points && (
            <div className={styles.text + ` ${show ? `${styles.show}` : ""}`}>
              <ul>
                {block.values[current].points.map((point, i) => (
                  <li key={point} style={{ transitionDelay: `.${i + 1}s` }}>
                    <Content text={point} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {block.values[current] && block.values[current].button && (
            <div className={styles.button}>
              <SharedTextButton
                button={block.values[current].button}
                onClick={() => setFormOpen(!formOpen)}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.imgContainer}>
          {block.values[current] && block.values[current].image && (
            <img src={block.values[current].image} className={styles.image} />
          )}
        </div>
      </div>
      <div className={styles.bottom} />
      {block.inputs && block.inputs.length > 0 && (
        <SlideoutForm
          block={block}
          formOpen={formOpen}
          setFormOpen={setFormOpen}
        />
      )}
    </section>
  );
};
