import React, { useEffect, useRef, useContext, useState } from 'react'
import { Context } from '../context'
import { Helmet } from 'react-helmet'
import { filterResults, SearchResults } from '../utilities'
import Footer from './footer'
import Header from './header'
import Main from './main'

import { author, description, metaTags, title, titleDivider } from '../settings/config.json'

import './reset.scss'
import './global.scss'
import * as styles from './layout.module.scss'
import { copyFile } from 'fs/promises'

type Layout = {
  children?: any,
  title: string,
  pageDescription?: string,
  blocks?: [any],
  trees?: object,
  articles?: [any],
  stories?: [any],
  searchIndexes?: [any]
}

export default ({ children, title: siteTitle = '', blocks, pageDescription = '', trees, articles, stories, searchIndexes }: Layout) => {
  const context = useContext(Context);
  const ref = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
 
  useEffect(() => {
    if (typeof window !== 'undefined') {

      const updateScroll = () => {
        context.setScroll(window.scrollY);
        if (window.scrollY > 15)
          context.setScrolled(true);
        else context.setScrolled(false);
      }
      
      updateScroll();

      context.setScroll(window.scrollY);

      window.addEventListener('scroll', updateScroll);
      
      return (() => {
        window.removeEventListener('scroll', updateScroll);
      })
    }
  }, [])

  useEffect(() => {
    if (searchIndexes && context) {
      const results = filterResults(searchIndexes, context);
      if (results)
        context.setMenuOpen(false);
      setSearchResults(results);
    }
  }, [context && context.search])

  const scrollTo = to => {
    if (ref.current)
      ref.current.scrollTop = to;
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop += context.mouseWheel
    }
  }, [context && context.mouseWheel])
  
  return (
    <div className={styles.layout} ref={ref}>
      <Helmet>
        <html lang='en' />
        <title>{siteTitle + ' ' + titleDivider + ' ' + `${title || '404: Page Not Found'}`}</title>
        <meta name='description' content={pageDescription || description} />
        <meta name='keywords' content={metaTags.join(' ')} />
        <meta name='author' content={author} />
        <script src="https://fast.wistia.com/embed/medias/xg1avbvcqn.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <Header scrollTo={scrollTo} />
      <SearchResults results={searchResults} />
      <Main blocks={blocks} scroll={context && context.scroll} trees={trees} articles={articles} stories={stories} scrollTo={scrollTo}>
        {children}
      </Main>
      <Footer scroll={context && context.scroll} />
    </div>
  )
}