// extracted by mini-css-extract-plugin
export var button = "contactForm-module--button--vYD6Z";
export var field = "contactForm-module--field--7bwPa";
export var form = "contactForm-module--form--WBUdh";
export var heading = "contactForm-module--heading--A1o-y";
export var input = "contactForm-module--input--NIwm5";
export var loader = "contactForm-module--loader--loolu";
export var message = "contactForm-module--message--OPvfH";
export var narrow = "contactForm-module--narrow--VSCvl";
export var section = "contactForm-module--section--nSFgD";
export var show = "contactForm-module--show--ovRTc";