import React, { useState, useRef,  useEffect, useContext } from 'react'
import SponsorContent from '../sponsorContent'
import * as styles from './sponsor.module.scss'
import { Context } from '../../../../context'
import { Content } from '../../../../utilities'

export default ({ block, scroll, trees, scrollTo }) => {
  const [activeCategory, setActiveCategory] = useState('');
  const context = useContext(Context);
  const [show, setShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [stick, setStick] = useState(false);
  const ref = useRef(null);
  const selectors = useRef(null);
  const container = useRef(null);
  const [dropOpen, setDropOpen] = useState(false);
  
  const handleCategoryClick = (label) => {
    setContentShow(false);
    setActiveCategory(label);
    setTimeout(() => {
      setContentShow(true);
    }, 100);
    if (context && typeof window !== 'undefined') {
      window.scrollTo(0, context.heroHeight);
    }
    setDropOpen(!dropOpen);
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      setActiveCategory(window.location.search.split('=')[1].split('%20').join(' '))
    } else {
      setActiveCategory(block.categories && block.categories.filter(category => !category.hide) && block.categories.filter(category => !category.hide)[0] ? block.categories.filter(category => !category.hide)[0].blockTitle : '')
    }
  }, [])

  useEffect(() => {
    const onWheel = e => {
      if (context) {
        context.setMouseWheel(e.deltaY)
      }
    }

    if (selectors.current) {
      selectors.current.addEventListener('wheel', onWheel);
    }
    return (() => {
      if (selectors.current) {
        selectors.current.removeEventListener('wheel', onWheel);
      }
    })
  }, [])

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
      setContentShow(true);
      if (window.innerWidth > 767 && ref.current.getBoundingClientRect().top <= window.innerWidth * .03) {
        setStick(true);
      } else if (window.innerWidth <= 767 && ref.current.getBoundingClientRect().top <= window.innerWidth * .08) {
        setStick(true);
      } else setStick(false);
    }
  }, [scroll])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.left + ` ${stick ? `${styles.stick}` : ''}` + ` ${dropOpen ? `${styles.dropOpen}` : ''}`} ref={selectors}>
        {block.categories && block.categories.length && block.categories.filter(category => !category.hide).length ? block.categories.filter(category => !category.hide).map(category => {
          if (category.pageLink) {
            return <div key={category.blockTitle} className={styles.category}>
              <a href={category.pageLink} target='_blank' rel='noreferrer' className={styles.categoryBtn}>
                <Content text={category.blockTitle} />
              </a>
            </div>
          } else {
              return <div key={category.blockTitle} className={styles.category + ` ${activeCategory === category.blockTitle ? `${styles.active}` : ''}` + ` ${stick ? `${styles.stick}` : ''}`}>
              <button
                className={styles.categoryBtn + ` ${activeCategory === category.blockTitle ? `${styles.active}` : ''}`}
                onClick={() => handleCategoryClick(category.blockTitle)}
              >
                <Content text={category.blockTitle} />
              </button>
            </div>
          }
        }) : null}
        <img src='chevron-down-1.svg' className={styles.chevron + ` ${stick ? `${styles.stick}` : ''}`} onClick={() => setDropOpen(!dropOpen)} />
      </div>
      <div className={styles.fill + ` ${stick ? `${styles.show}` : ''}`} />
      <div className={styles.right + ` ${contentShow ? `${styles.show}` : ''}`} ref={container}>
      <div className={styles.padding + ` ${stick ? `${styles.show}` 
        : ''}` + ` ${dropOpen ? `${styles.open}` :''}`} />
        {block.categories && block.categories.length && block.categories.filter(category => !category.hide).length ? block.categories.filter(category => !category.hide).filter(category => category.blockTitle === activeCategory).map(category => {
          return <SponsorContent key={category} block={category} scroll={scroll} category={activeCategory} trees={trees} />
        }) : null}
      </div>
    </section>
  )
}
