import React, { useState, useContext, Component } from 'react'
import { Context } from '../../context'
import Blocks from '../../settings/header.json'
import Components from './components'
import * as styles from './header.module.scss'


export default ({ scrollTo }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const context = useContext(Context);

  const handleClick = () => {
    if (context && !context.menuOpen)
      setSearchOpen(!searchOpen);
  }

  return (
    <header className={styles.header}>
    <div className={styles.container}>
      {Blocks.headerBlocks.map(block => (
        block.template === 'header-logo' ? (
          <Components.Logo key={block.template} block={block} scrollToTop={() => scrollTo(0)} />
        ) : block.template === 'header-menu-button' ? (
          <Components.MenuButton key={block.template} block={block} setSearchOpen={setSearchOpen} />
        ) : block.template === 'header-search' ? (
          <Components.SearchBox key={block.template} />
        ) : block.template === 'header-menu' ? (
          <Components.Menu key={block.template} block={block} />
        ) : null
      ))}
      <div className={styles.right}>
        <Components.Donate key={'header-donate'} block={Blocks.headerBlocks.filter(block => block.template === 'header-donate')[0]} />
        <Components.LanguageButton />
      </div>
      <button className={styles.searchBtn} onClick={handleClick} />
      <Components.SearchBoxMobile searchOpen={searchOpen} />
      <Components.Languages />
      <Components.DonateForm block={Blocks.headerBlocks.filter(block => block.template === 'header-donate')[0]}/>
    </div>
  </header>
  )
}