import React, { useContext } from 'react'
import { Context } from '../../../../context'
import * as styles from './donateForm.module.scss'

export default ({ block }) => { 
  const context = useContext(Context);
  
  const handleClick = () => {
    if (context) {
      context.setDonateOpen(false);
      context.setDonateUrl('');
    }
  }

  return (
    <section className={styles.section + ` ${context && context.donateOpen ? `${styles.open}` : ''}`}>
      <button
        className={styles.close}
        style={{ backgroundImage: `url(/x.svg)` }}
        onClick={handleClick}
      />
      <div className={styles.formContainer}>
        <div className={styles.formWrapper}>
          <iframe
            height="349"
            name="donorbox"
            scrolling="yes"
            src={context && context.donateUrl ? context.donateUrl : block.url}
            width="560">
          </iframe>
        </div>
      </div>
    </section>
  )
}