import AnnualReports from './annualReports'
import AvailableSpecies from './availableSpecies'
import Blog from './blog'
import Checkout from './checkout'
import CommunityService from './communityService'
import Contact from './contact'
import Education from './education'
import EducationContent from './educationContent'
import Eventbrite from './eventbrite'
import FooterForm from './footerForm'
import FooterImage from './footerImage'
import Hero from './hero'
import HomeOptionChanger from './homeOptionChanger'
import ImageText from './imageText'
import Infographic from './infographic'
import LegalPrivacy from './legalPrivacy'
import MasterPlan from './masterPlan'
import NotFound from './notFound'
import OurMission from './ourMission'
import Signs from './signs'
import SimpleForm from './simpleForm'
import SimpleText from './simpleText'
import Sponsor from './sponsor'
import SponsorContent from './sponsorContent'
import Store from './store'
import StoreHero from './storeHero'
import Stories from './stories'
import StoriesFooter from './storiesFooter'
import Team from './team'
import Timeline from './timeline'
import TreeSelector from './treeSelector'
import Vomo from './vomo'
import WhyTrees from './whyTrees'

export default {
  'main-annual-reports': AnnualReports,
  'main-available-species': AvailableSpecies,
  'main-blog': Blog,
  'main-checkout': Checkout,
  'main-community-service': CommunityService,
  'main-contact': Contact,
  'main-education': Education,
  'main-education-content': EducationContent,
  'main-eventbrite': Eventbrite,
  'main-footer-form': FooterForm,
  'main-footer-image': FooterImage,
  'main-hero': Hero,
  'main-home-option-changer': HomeOptionChanger,
  'main-image-text': ImageText,
  'main-infographic': Infographic,
  'main-legal-privacy': LegalPrivacy,
  'main-master-plan': MasterPlan,
  'main-not-found': NotFound,
  'main-our-mission': OurMission,
  'main-signs': Signs,
  'main-simple-form': SimpleForm,
  'main-simple-text': SimpleText,
  'main-sponsor': Sponsor,
  'main-sponsor-content': SponsorContent,
  'main-store': Store,
  'main-store-hero': StoreHero,
  'main-stories': Stories,
  'main-stories-footer': StoriesFooter,
  'main-team': Team,
  'main-timeline': Timeline,
  'main-tree-selector': TreeSelector,
  'main-vomo': Vomo,
  'main-why-trees': WhyTrees
}