import React, { useEffect, useState, useRef } from 'react'
import * as styles from './bodyData.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.list + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      {block.data.map((data, i) => (
        <div key={i} className={styles.container}>
          <div className={styles.item +
            ` ${block.data.length <= 1 && data.label.length <= 5 ? `${styles.narrow}` : ''}` + ` ${data.value.length > 20 ? `${styles.small}` : ''}`}>
            <Content text={data.label} />:<span><Content text={data.value} /></span>
          </div>
          {data.text &&
          <div className={styles.text}>
            <span><Content text={data.text} /></span>
          </div>}
        </div>
      ))}
      <h4 className={styles.includes}><Content text='Includes:' /></h4>
      {block.includes.map((item, i) => (
        <div key={item} className={styles.listItem} style={{transitionDelay: `.${i + 2}s`}}>
          <p>-</p>
          <p className={styles.text}><Content text={item} /></p>
        </div>
      ))}
      {block.icons.length > 0 && block.icons.map(({icon}, i) => (
        <img key={icon} src={icon} className={styles.icon} style={{transitionDelay: `.${i + 4}s`}}/>
      ))}
    </div>
  )
}