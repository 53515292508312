// extracted by mini-css-extract-plugin
export var benchMarkGroups = "benchmarks-module--benchMarkGroups--FcrSW";
export var benchmark = "benchmarks-module--benchmark--gfD0r";
export var benchmarks = "benchmarks-module--benchmarks--5cSJe";
export var bottomText = "benchmarks-module--bottomText--ESiM8";
export var container = "benchmarks-module--container--cmJBV";
export var heading = "benchmarks-module--heading--Cg5Pi";
export var headingContainer = "benchmarks-module--headingContainer--7ViYb";
export var image = "benchmarks-module--image--dtX86";
export var imageContainer = "benchmarks-module--imageContainer--m-xua";
export var indent = "benchmarks-module--indent--0bbFv";
export var label = "benchmarks-module--label--ukzta";
export var narrow = "benchmarks-module--narrow--QRk-X";
export var numContainer = "benchmarks-module--numContainer--L4i45";
export var number = "benchmarks-module--number--Ks7YA";
export var orange = "benchmarks-module--orange--PLKCA";
export var section = "benchmarks-module--section--2ANET";
export var show = "benchmarks-module--show--whs5W";
export var topText = "benchmarks-module--topText--VbmOI";