import React from 'react'
import * as styles from './contactInfo.module.scss'
import { Content } from '../../../../utilities'

export default ({ block, show }) => {



  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      <a className={styles.number} href={`tel:${block.numberLink}`}>
        <Content text={block.number} />
      </a>
      <div className={styles.address}>
        <Content text={block.address} />
      </div>
    </section>
  )
}