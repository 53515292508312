import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context";
import parse from "html-react-parser";
import getTranslation from "./getTranslation";
import { marked } from "marked";

export default ({ text: markdownText }) => {
  const context = useContext(Context);
  const [translatedText, setTranslatedText] = useState("");
  const text = React.useMemo(() => marked.parse(markdownText ?? ""), [markdownText]);

  useEffect(() => {
    const translateText = async () => {
      if (context && context.language !== "en") {
        const result = await getTranslation(text, context.language);
        if (result["data"][0]) {
          setTranslatedText(result["data"][0]);
        }
      }
    };
    translateText();
  }, [context && context.language, text]);

  const textFix =
    text &&
    text.length &&
    text.replace(
      "<a href",
      '<a target="_blank" rel="noopener noreferrer" href'
    );

  return (
    <>
      {context && context.language !== "en"
        ? parse(translatedText ? translatedText : "")
        : parse(textFix || "")}
    </>
  );
};
