import React from 'react'
import * as styles from './involvementPages.module.scss'
import { Content, ForestryLink } from '../../../../utilities'

export default ({ block, show }) => {

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      <h3 className={styles.heading}>
        <Content text={block.label} />
      </h3>
      <div className={styles.links}>
        {block.pages.map(page => (
          <ForestryLink key={page.page} link={page} className={styles.link} activeClassName={styles.active} />
        ))}
      </div>
    </section>
  )
}