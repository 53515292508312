import React, { useState, useEffect, useRef } from 'react'
import * as styles from './legalPrivacy.module.scss'
import parse from 'html-react-parser'
import SharedTextButton from '../sharedTextButton'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <h1 className={styles.title}>{block.blockTitle}</h1>
      <h3 className={styles.subtitle}>{block.privacyHeading}</h3>
      <div className={styles.text}>
        {parse(block.privacyText)}
      </div>
      <h3 className={styles.subtitle}>{block.legalHeading}</h3>
      <div className={styles.text}>
        {parse(block.legalText)}
      </div>
    </section>
  )
}