import React, { useState, useEffect, useRef, useContext } from 'react'
import * as styles from './signs.module.scss'
import parse from 'html-react-parser'
import { Content, Leaf } from '../../../../utilities'
import { Context } from '../../../../context'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const context = useContext(Context);
  const [season, setSeason] = useState('summer');
  const [mobile, setMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 767 ? true :
    false
  )

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 767)
        setMobile(false);
      else setMobile(true);
    }
    resize();

    window.addEventListener('resize', resize);

    return (() => {
      window.removeEventListener('resize', resize);
    })
  }, [])

  useEffect(() => {
    if (context && context.season) {
      setSeason(context.season);
    }
  }, [context && context.season])

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      {block.leafOne && block.leafOne.fall && block.leafOne.summer &&
      <Leaf 
        width={mobile && season === 'fall' ? '35vw' : mobile ? '40vw' : season === 'fall' ? '25vw' : '30vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafOne.fall : block.leafOne.summer}
        top={20}
        left={mobile ? 0 : -5}
        direction={'up'}
        speed={7}
      />}
      <div className={styles.content + ` ${show ? `${styles.show}` : ''}`}>
        <div className={styles.iconContainer}>
          {block.icon &&
          <img src={block.icon} className={styles.icon} />}
        </div>
        <div className={styles.text}>
          <Content text={block.body} />
        </div>
        <div className={styles.footer}>
          <Content text={block.footer} />
        </div>
      </div>
      <img src={block.image} className={styles.image + ` ${show ? `${styles.show}` : ''}`} />
    </section>
  )
}