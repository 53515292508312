// extracted by mini-css-extract-plugin
export var bottom = "footerImage-module--bottom--Si-Ez";
export var buttonContainer = "footerImage-module--buttonContainer--5fERm";
export var container = "footerImage-module--container--FJYve";
export var content = "footerImage-module--content--diJ6c";
export var image = "footerImage-module--image--KXaW5";
export var left = "footerImage-module--left--DrHdy";
export var long = "footerImage-module--long--5p5o9";
export var right = "footerImage-module--right--cnLCJ";
export var section = "footerImage-module--section--gP4mw";
export var show = "footerImage-module--show--yFYVV";
export var text = "footerImage-module--text--P8+dc";