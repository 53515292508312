import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../../context'
import { getTranslation } from '../../../../../utilities'

import * as styles from './searchBoxMobile.module.scss'

export default ({ searchOpen }) => {
  const context = useContext(Context);
  const [input, setInput] = useState('');

  const onSubmit = async e => {
    e.preventDefault();
    if (context.language && context.language !== 'en') {
      const result = await getTranslation(input, 'en');
      if (result['data'][0]) {
        context.setSearch(result['data'][0]);
      }
    } else
    context.setSearch(input)
  }

  const [placeholder, setPlaceholder] = useState('Search');

  const handleChange = e => {
    setInput(e.target.value);
  }

  useEffect(() => {
    const translateText = async () => {
      if (context && context.language !== 'en') {
        const result = await getTranslation('Search', context.language);
        if (result['data'][0]) {
          setPlaceholder(result['data'][0]);
        }
      } else setPlaceholder('Search')
    }
    translateText();
  }, [context && context.language])
  
  return (
    <div className={styles.search + ` ${searchOpen ? `${styles.open}` : ""}`}>
      <div className={styles.container}>
        <form onSubmit={onSubmit}>
          <input
            className={styles.input}
            type="text"
            onChange={handleChange}
            value={input}
            placeholder={`${placeholder}...`}
          />
        </form>
      </div>
    </div>
  )
}