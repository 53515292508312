// extracted by mini-css-extract-plugin
export var active = "menu-module--active--0ZHmT";
export var button = "menu-module--button--9oDp3";
export var container = "menu-module--container--Il0QN";
export var error = "menu-module--error--XTg3C";
export var footerHeading = "menu-module--footerHeading--vLdOm";
export var footerSubheading = "menu-module--footerSubheading--9zwDZ";
export var form = "menu-module--form---qHx8";
export var input = "menu-module--input--VvMfm";
export var mainLink = "menu-module--mainLink--Ia3zS";
export var open = "menu-module--open--WcZj5";
export var searchBackground = "menu-module--searchBackground--GCRIo";
export var secondaryLink = "menu-module--secondaryLink--AFoq-";
export var section = "menu-module--section--SbrEK";
export var space = "menu-module--space--cNfaq";