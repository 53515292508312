import React, { useState, useEffect, useRef } from 'react'
import * as styles from './whyTrees.module.scss'
import parse from 'html-react-parser'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      <img className={styles.image} src={block.image} />
      <div className={styles.right}>
        <div className={styles.top}>
          <h2 className={styles.heading}>
            <Content text={block.blockTitle} />
          </h2>
          <div className={styles.body}>
            <Content text={block.body} />
          </div>
        </div>
        <div className={styles.bottom}>
          {block.stats && block.stats.map((item, i) => (
            <a href={item.link} target='__blank' rel='noreferrer' key={item.stat} className={styles.listItem} style={{transitionDelay: `.${i * 2}s`}}>
              <div className={styles.iconContainer}>
                {item.icon &&
                <img src={item.icon} className={styles.icon} />}
              </div>
              <div className={styles.text}>
                <p className={styles.stat}>
                  <Content text={item.stat} />
                </p>
                <p className={styles.description}>
                  <Content text={item.description} />
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}