import React, { useState, useEffect, useRef } from 'react'
import * as styles from './imageText.module.scss'
import parse from 'html-react-parser'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .8)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.left}>
        <div className={styles.heading}>
          <Content text={block.blockTitle} />
        </div>
        <img src={block.image} className={styles.image} />
      </div>
      <div className={styles.right + ` ${!block.icons ? `${styles.wide}` : ''}`}>
        <div className={styles.container}>
          <div className={styles.body}>
            <Content text={block.body} />
          </div>
          {block.icons &&
          <div className={styles.icons}>
            {block.icons.map(({icon}) => (
              <img key={icon} src={icon} className={styles.icon} />
            ))}
          </div>}
        </div>
      </div>
    </section>
  )
}