export default (searchIndexes, context) => searchIndexes.filter(index => {
  if (context && context.search) {
    const search = context.search.toLowerCase();
    if (index?.label?.toLowerCase().includes(search) || index?.text?.toLowerCase().includes(search)) {
      return index;
    }
  }
}).map(index => {
  const search = context.search.toLowerCase();
  const copy = {...index};
  copy.label = copy.label.split('<strong>').join('').split('</strong>').join('');
  copy.text = copy.text.split('<strong>').join('').split('</strong>').join('');
  const start = copy.label.toLowerCase().indexOf(search.toLowerCase());
  const end = start + search.length;
  if (start === 0) {
    copy.label = '<em>' + copy.label.slice(0, end) + '</em>' + copy.label.slice(end);
  } else if (start !== -1) {
    copy.label = (copy.label.slice(0, start) + '<em>' + copy.label.slice(start, end) + '</em>' + copy.label.slice(end));
  }
  const textStart = copy.text.toLowerCase().indexOf(search.toLowerCase());
  const textEnd = textStart + search.length;
  if (textStart === 0) {
    copy.text = '<em>' + copy.text.slice(0, textEnd) + '</em>' + copy.text.slice(textEnd);
  } else if (textStart !== -1) {
    copy.text = (copy.text.slice(0, textStart) + '<em>' + copy.text.slice(textStart, textEnd) + '</em>' + copy.text.slice(textEnd));
  }
  return copy;
})