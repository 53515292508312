import React, { useState, useEffect, useRef } from 'react'
import * as styles from './annualReports.module.scss'
import { Content } from '../../../../utilities'
import * as scrollFn from 'scroll'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [moves, setMoves] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 767 ? 1 :3
  );
  
  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  const colors = ['#FFA225', '#067BC2', '#32B44A'];

  const getColor = i => {
    if (i <= 2)
      return colors[i];
    else if (i % 3 === 0)
      return colors[0];
    else if ((i - 1) % 3 === 0)
      return colors[1];
    else
      return colors[2];
  }

  useEffect(() => {
    const reset = () => {
      if (ref.current) {
        ref.current.scrollLeft = 0;
        if (typeof window !== 'undefined' && window.innerWidth > 767 && block.reports.length <= 3) {
          setShowLeft(false);
          setShowRight(false);
        }
        else if (typeof window !== 'undefined' && window.innerWidth > 767) {
          setMoves(3);
          setShowLeft(false);
          setShowRight(true);
        } else {
          setMoves(1);
          setShowLeft(false);
          setShowRight(true);
        }
      }
    }
    reset();
    // window.addEventListener('resize', reset)

    // return (() => {
    //   window.removeEventListener('resize', reset)
    // })
  }, [])

  const moveLeft = () => {
    setDisable(true);
    let distance = 0;
    if (window.innerWidth > 767)
      distance = window.innerWidth / 100 * 27.5;
    else
      distance = window.innerWidth;
    if (ref.current) {
      setShowRight(true);
      scrollFn.left(ref.current, ref.current.scrollLeft - distance)
      if (window.innerWidth > 767 && moves === 4) {
        setShowLeft(false);
      } else if (window.innerWidth <= 767 && moves === 2) {
        setShowLeft(false);
      }
      setMoves(moves - 1);
    }
    setTimeout(() => {
      setDisable(false);
    }, 300)
  }

  const moveRight = () => {
    setDisable(true);
    let distance = 0;
    if (window.innerWidth > 767)
      distance = window.innerWidth / 100 * 27.5;
    else
      distance = window.innerWidth;
    if (ref.current) {
      setShowLeft(true);
      scrollFn.left(ref.current, ref.current.scrollLeft + distance)
      setMoves(moves + 1);
      if (moves === block.reports.length - 1)
        setShowRight(false);
    }
    setTimeout(() => {
      setDisable(false);
    }, 300)
  }

  return (
    <section className={styles.section} id={block.template.replace('main-', '')}>
      <h2 className={styles.heading}>
        <Content text={block.blockTitle} />
      </h2>
      <div className={styles.buttons}>
        <button 
          className={styles.left + ` ${!showLeft ? `${styles.hide}` :''}` + ` ${disable ? `${styles.disable}` : ''}`}
          onClick={moveLeft}><img src='/button.svg' className={styles.leftIcon} />
        </button>
        <button 
          className={styles.right + ` ${!showRight ? `${styles.hide}` :''}` + ` ${disable ? `${styles.disable}` : ''}`}
          onClick={moveRight}><img src='/button.svg' className={styles.rightIcon} />
        </button>
        <span className={styles.count}>{moves}/{block.reports.length}</span>
      </div>
      <div 
        className={styles.reports + ` ${show ? `${styles.show}` : ''}`}
        ref={ref}
      >
        {block.reports.length && block.reports.map((report, i) => (
          <div
            key={i} 
            className={styles.report}
            style={{backgroundColor: getColor(i), transitionDelay: `.${i}s`}}
          >
            <div className={styles.yearContainer}>
              <p className={styles.year}>{report.year}</p>
            </div>
            <div className={styles.container}>
              <p className={styles.title}>
                <Content text={report.title} />
              </p>
              <p className={styles.description + ` ${report.description.length > 400 ? `${styles.small}` : ''}`}>
                <Content text={report.description} />
              </p>
            </div>
            <button className={styles.file}>
              <a href={report.url} target='__blank' rel='noreferrer'>
                <img src={'/arrow-right.svg'} className={styles.arrow} />
              </a>
            </button>
          </div>
        ))}
        <div className={styles.padding} />
      </div>
      <div className={styles.background} />
    </section>
  )
}