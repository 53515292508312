import React from 'react'
import * as styles from './topTextImage.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block }) => {


  return (
    <div className={styles.top}>
      <h2 className={styles.heading}>
        <Content text={block.heading} />
      </h2>
      <h4 className={styles.subheading}>
        <Content text={block.subheading} />
      </h4>
      <div className={styles.background} />
      <img src={block.firstImage} className={styles.firstImage} />
    </div>
  )
}
