import React, { useContext, useState, useEffect } from 'react'
import * as styles from './checkout.module.scss'
import { Context } from '../../../../context'
import { Content } from '../../../../utilities'
import Loader from 'react-loader-spinner'

export default ({ block }) => {
  const context = useContext(Context);
  const [checkoutItems, setCheckoutItems] = useState(null);
  const [offers, setOffers] = useState(false);
  const [contact, setContact] = useState('');
  const [ready, setReady] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [openQuantity, setOpenQuantity] = useState('');
  const [editOpen, setEditOpen] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    country: '',
    province: '',
    zip: '',
    address1: '',
    address2: '',
    city: ''
  });

  useEffect(() => {
    if (context && context.checkout) {
      if (typeof window !== undefined && window.localStorage) {
        window.localStorage.setItem('shopifyCheckoutId', context.checkout.id);
      }
      setCheckoutItems(context.checkout.lineItems);
      context.checkout.lineItems.forEach(item => {
        setQuantities(quantities => {
          const copy = {...quantities};
          copy[item.variant.id] = item.quantity;
          return copy;
        })
      })
    }
  }, [context && context.checkout])

  const handleChange = e => {
    setReady(false);
    const target = e.target;
    setFormValues(values => {
      const copy = {...values};
      if (target.name === 'offers')
        copy[target.name] = target.checked;
      else copy[target.name] = target.value;
      return copy;
    })
  }

  // const handleContactChange = e => {
  //   setContact(e.target.value);
  // }

  // const handleOfferChange = e => {
  //   setOffers(e.target.value);
  // }

  useEffect(() => {
    if (context && context.checkout && context.client) {
      const checkout = context.checkout;
      const client = context.client;
      client.checkout.updateShippingAddress(checkout.id, formValues).then(checkout => {
        setReady(true);
      });
    }
  }, [formValues])

  const handleEdit = (id, e) => {
    const value = e.target.value
    setOpenQuantity(value);
  }
  
  const handleQuantityChange = id => {
    if (context) {
      const client = context.client;
      const checkout = context.checkout;
      setQuantities(quantities => {
        const copy = {...quantities};
        copy[id] = openQuantity;
        return copy;
      })

      const lineItemsToUpdate = [
        {id: id, quantity: +openQuantity}
      ];

      client.checkout.updateLineItems(checkout.id, lineItemsToUpdate).then((checkout) => {
        context.setCheckout(checkout);
      });
      setEditOpen(false);
    } 
  }

  return (
    <section className={styles.section} id={block.template.replace('main-', '')}>
      <div className={styles.left}>
        <h1 className={styles.heading}>
          <Content text={block.blockTitle} />
        </h1>
        {context && context.checkout && context.checkout.lineItems.length > 0 ? (
          <>
            <h3 className={styles.formHeading}><Content text='Contact info' /></h3>
            <form className={styles.form}>
              {/* <div className={styles.colOne}>
                <label className={styles.label} htmlFor='contact'>Email or phone</label>
                <input className={styles.input} value={contact} onChange={handleContactChange} name='contact' id='contact' />
              </div>

              <div className={styles.checkbox}>
                <label className={styles.label}>
                  <input
                    name="offers"
                    type="checkbox"
                    checked={offers}
                    onChange={handleOfferChange}
                  />
                  <span className={styles.checkmark}></span>
                  Keep me up to date on news and exclusive offers
                </label>
              </div> */}
              
              <div className={styles.colTwo}>
                <label className={styles.label} htmlFor='firstName'><Content text='First name' /></label>
                <input
                  className={styles.input}
                  value={formValues.firstName}
                  name='firstName'
                  id='firstName'
                  onChange={handleChange}
                />
              </div>

              <div className={styles.colTwo}>
                <label className={styles.label} htmlFor='last-name'><Content text='Last name' /></label>
                <input
                  className={styles.input}
                  name='lastName'
                  id='lastName'
                  onChange={handleChange}
                  value={formValues.lastName}
                />
              </div>

              <div className={styles.colOne}>
                <label className={styles.label} htmlFor='address1'><Content text='Address' /></label>
                <input 
                  className={styles.input}
                  name='address1'
                  id='address1'
                  onChange={handleChange}
                  value={formValues.address1}
                />
                <input 
                  className={styles.input}
                  name='address2'
                  id='address2'
                  onChange={handleChange}
                  value={formValues.address2}
                />
              </div>

              <div className={styles.colOne}>
                <label className={styles.label} htmlFor='city'><Content text='City' /></label>
                <input
                  className={styles.input}
                  name='city'
                  id='city'
                  onChange={handleChange}
                  value={formValues.city}
                />
              </div>

              <div className={styles.colThree}>
                <label className={styles.label} htmlFor='country'><Content text='Region' /></label>
                <input 
                  className={styles.input}
                  name='country'
                  id='country'
                  onChange={handleChange}
                  value={formValues.country}
                />
              </div>

              <div className={styles.colThree}>
                <label className={styles.label} htmlFor='province'><Content text='State' /></label>
                <input
                  className={styles.input}
                  name='province'
                  id='province'
                  onChange={handleChange}
                  value={formValues.province}
                />
              </div>
              
              <div className={styles.colThree}>
                <label className={styles.label} htmlFor='zip'><Content text='Zip' /></label>
                <input
                  className={styles.input}
                  name='zip'
                  id='zip'
                  onChange={handleChange}
                  value={formValues.zip}
                />
              </div>
              {context && context.checkout && context.checkout.lineItems.length > 0 &&
              <button type='button' className={styles.button + ` ${!ready ? `${styles.inactive}` : ''}`}>
                <a href={context && context.checkout && context.checkout.webUrl} target='__blank' rel='noreferrer'>
                  <Content text='Continue to payment' />
                  <img src='/button.svg' className={styles.icon} />
                </a>
                <div className={styles.loader}>
                  <Loader type="TailSpin" color="#0C3507" height={20} width={20} />
                </div>
              </button>}
            </form>
          </>
        ): <h2 className={styles.emptyCart}><Content text='There are no items in your cart.' /></h2>}
        
      </div>
      <div className={styles.right}>
        {checkoutItems && checkoutItems.map((item, idx) => (
          <div key={item.id} className={styles.item}>
            <div className={styles.imageCount} onMouseDown={() => {
              setOpenQuantity(item.quantity);
              setEditOpen(idx);
            }}>
              <div className={styles.imageContainer}>
                <img src={item.variant.image.src} className={styles.image} />
                <div className={styles.count}>
                  <p>{item.quantity}</p>
                </div>
              </div>
            </div>
            <p className={styles.title}><Content text={item.title} /></p>
            <p className={styles.price}>${(+item.variant.price * item.quantity).toFixed(2)}</p>
            <div className={styles.edit + ` ${editOpen === idx ? `${styles.open}` : ''}`}>
              <label className={styles.label} htmlFor={`edit-${item.title}`}><Content text='Quantity' />:</label>
              <input 
                className={styles.editInput}
                value={openQuantity}
                onChange={e => handleEdit(item.variant.id, e)}
              />
              <button 
                className={styles.editBtn} 
                onClick={() => handleQuantityChange(item.id)}>
                  <Content text='Edit' />
              </button>
              <button className={styles.close} onClick={() => setEditOpen(false)}><Content text='close' /></button>
            </div>
          </div>
        ))}
        {context && context.checkout && context.checkout.subtotalPrice &&
        <div className={styles.field}>
          <p className={styles.label}><Content text='Sub total' /></p>
          <p className={styles.value}>
            ${context.checkout.subtotalPrice}
          </p>
        </div>}
        {context && context.checkout && context.checkout.totalPrice &&
        <div className={styles.field}>
          <p className={styles.label}><Content text='Shipping' /></p>
          <p className={styles.value}>
            <Content text='Calculated next step' />
          </p>
        </div>}
        {context && context.checkout && context.checkout.totalPrice &&
        <div className={styles.field + ` ${styles.totalField}`}>
          <p className={styles.label}><Content text='Total' /></p>
          <p className={styles.totalValue}>
          ${context.checkout.totalPrice}
          </p>
        </div>}
      </div>
    </section>
  )
}