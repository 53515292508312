// extracted by mini-css-extract-plugin
export var bottom = "ourMission-module--bottom--y5wjw";
export var button = "ourMission-module--button--nZGCV";
export var container = "ourMission-module--container--Zjr4p";
export var current = "ourMission-module--current--3pSDa";
export var heading = "ourMission-module--heading--lhPQc";
export var image = "ourMission-module--image--1N8Ku";
export var imgContainer = "ourMission-module--imgContainer--8Lj1T";
export var middle = "ourMission-module--middle--AHIxd";
export var right = "ourMission-module--right--1M90a";
export var section = "ourMission-module--section--dWxaZ";
export var show = "ourMission-module--show--hrvXH";
export var text = "ourMission-module--text--2Ev4b";
export var value = "ourMission-module--value--bdH9I";
export var values = "ourMission-module--values--tw6mf";
export var valuesHeading = "ourMission-module--valuesHeading--dQj7w";