import React, { useState, useRef, useEffect } from 'react'
import * as styles from './notFound.module.scss'
import { Content } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100)
  }, [])


  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.left + ` ${show ? `${styles.show}` : ''}`}>
        <Content text={block.text} />
      </div>
      <div className={styles.right + ` ${show ? `${styles.show}` : ''}`}>
        <img src={block.image} className={styles.image} />
      </div>
    </section>
  )
}