// extracted by mini-css-extract-plugin
export var bottom = "storiesFooter-module--bottom--Zqpwd";
export var buttonContainer = "storiesFooter-module--buttonContainer--bC0gJ";
export var container = "storiesFooter-module--container--LuQ5B";
export var content = "storiesFooter-module--content--HqB4Y";
export var image = "storiesFooter-module--image--9hKXI";
export var left = "storiesFooter-module--left--iVTuP";
export var right = "storiesFooter-module--right--t5Tp3";
export var section = "storiesFooter-module--section--qUSJ+";
export var show = "storiesFooter-module--show--wyVHp";
export var text = "storiesFooter-module--text--c3w+j";