import React, { useEffect, useState, useRef } from 'react'
import * as styles from './bottomIconList.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ items, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .9)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.list + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
    {items.map((item, i) => (
      <div key={i} className={styles.listItem} style={{transitionDelay: `.${i + 2}s`}}>
        <div className={styles.iconContainer}>
          {item.icon &&
          <img src={item.icon} className={styles.icon} />}
        </div>
        <div className={styles.text}>
          <p className={styles.heading}>
            <Content text={item.heading} />
          </p>
          <p className={styles.body}>
            <Content text={item.body} />
          </p>
        </div>
      </div>
    ))}
  </div>
  )
}  
