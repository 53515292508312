import React, { useContext } from 'react'
import { Context } from '../../../../context'

import * as styles from './menuButton.module.scss'

export default ({ block, setSearchOpen }) => {
  const context = useContext(Context);

  const handleClick = () => {
    if (context) {
      context.setMenuOpen(!context.menuOpen);
      if (!context.menuOpen) {
        setSearchOpen(true);
        context.setLanguagesOpen(false);
      } else setSearchOpen(false);
    }
  }
  
  return (
    <button className={styles.button} onClick={handleClick}>
      {context && context.menuOpen ? (
        <img src='/x.svg' className={styles.x} />
      ) :
      <>
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.shortLine} />
      </>}
    </button>
  )
}