// extracted by mini-css-extract-plugin
export var arrow = "annualReports-module--arrow--zuCYO";
export var background = "annualReports-module--background--f8Nu2";
export var buttons = "annualReports-module--buttons--0ZDLt";
export var container = "annualReports-module--container--QPzSU";
export var count = "annualReports-module--count--ywZAg";
export var description = "annualReports-module--description--Li4fX";
export var disable = "annualReports-module--disable--J-ar8";
export var file = "annualReports-module--file--dFxKI";
export var heading = "annualReports-module--heading--dLv79";
export var hide = "annualReports-module--hide--Uq34I";
export var left = "annualReports-module--left--xUpw-";
export var leftIcon = "annualReports-module--leftIcon--cx1HL";
export var padding = "annualReports-module--padding--ja+Xb";
export var report = "annualReports-module--report--5LXUJ";
export var reports = "annualReports-module--reports--1Fz6t";
export var right = "annualReports-module--right--PyWYk";
export var rightIcon = "annualReports-module--rightIcon--pzGMw";
export var section = "annualReports-module--section--Um78F";
export var show = "annualReports-module--show--Gz8jc";
export var small = "annualReports-module--small--DyIbe";
export var title = "annualReports-module--title--dbffw";
export var year = "annualReports-module--year--PvErs";
export var yearContainer = "annualReports-module--yearContainer--vUQJg";