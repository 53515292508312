import { getTranslation } from '../../../../../utilities'

export default async (search, articles, active, language) => {
  let text = search;
  if (language && language !== 'en') {
    const result = await getTranslation(search, 'en');
    if (result['data'][0]) {
      text = result['data'][0];
    }
  }
  return articles.filter(({data: {title, subheading, body, category}}) => {
    if (text && active) {
      return ((title && title.toLowerCase().includes(text.toLowerCase())) || (subheading && subheading.toLowerCase().includes(text.toLowerCase())) || (body && body.toLowerCase().includes(text.toLowerCase()))) && (category === active || active === 'All');
    }
    if (active) {
      return category === active || active === 'All';
    }
    if (text) {
      return (title && title.toLowerCase().includes(text.toLowerCase())) || (subheading && subheading.toLowerCase().includes(text.toLowerCase())) || (body && body.toLowerCase().includes(text.toLowerCase()));
    }
    return true;
  })
}