import React, { useEffect, useRef, useState} from 'react'
import * as styles from './canopyGoal.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <h2 className={styles.heading}>
        <Content text={block.canopyGoalHeading} />
      </h2>
      <div className={styles.numbers}>
        <p>{block.statOne}</p>
        <img src={block.arrow} className={styles.arrow} />
        <p>{block.statTwo}</p>
      </div>
      <div className={styles.body}>
        <Content text={block.canopyGoalBody} />
      </div>
      <div className={styles.maps}>
        {block.maps.map(map => (
          <div
            key={map.label}
            className={styles.mapGroup}>
            <p>
              <Content text={map.label} />
            </p>
            <img src={'/curvy-arrow.svg'} className={styles.curvyArrow} />
            <img src={map.map} className={styles.map} />
          </div>
        ))}
      </div>
      <div className={styles.bottom} />
    </section>
  )
}