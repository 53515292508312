import React from 'react'
import * as styles from './copyright.module.scss'
import { Link } from 'gatsby'
import { ForestryLink } from '../../../../utilities'

export default ({ block, show }) => {
  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      <div className={styles.container}>
        <p className={styles.copyright}>
          {block.copyright} | <span>
            {block.legal.linkType === 'page' ? (
              <ForestryLink link={block.legal} className={styles.link} />
            ) : block.legal.linkType === 'document' ? (
              <Link to={`/${block.legal.document}`} className={styles.link}>
                {block.legal.label}
              </Link>
            ) : <a href={block.legal.url} className={styles.link}>
                {block.legal.label}
              </a>}
          </span>
        </p>
        <p className={styles.mobileCopyright}>
          {block.copyright}
        </p>
        <p className={styles.mobileLegal}>
          {block.legal.linkType === 'page' ? (
            <ForestryLink link={block.legal} className={styles.link} />
          ) : block.legal.linkType === 'document' ? (
            <Link to={`/${block.legal.document}`} className={styles.link}>
              {block.legal.label}
            </Link>
          ) : <Link to={block.legal.url} className={styles.link}>
              {block.legal.label}
            </Link>}
        </p>
      </div>
    </section>
  )
}