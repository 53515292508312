import React, { useEffect, useState, useRef } from 'react'
import * as styles from './topRichText.module.scss'
import parse from 'html-react-parser'
import { Content } from '../../../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .9)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.container}>
      <div className={styles.text + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
        <Content text={block.richTextContent} />
      </div>
      {block.icons.length > 0 && block.icons.map(icon => (
        <img src={icon.icons} className={styles.icon + ` ${show ? `${styles.show}` : ''}`} />
      ))}
    </div>
  )
}
