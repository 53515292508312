import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import { Context } from "../../../../context";
import { Content } from "../../../../utilities";

import * as styles from "./treeSelector.module.scss";
import {
  GoodForSelector,
  GrowthRateSelector,
  HeightSlider,
  LightSelector,
  SoilSelector,
  SpreadSlider,
  TypeSelector,
  ZoneSelector,
} from "./components";

export default ({ block, scroll, trees }) => {
  const context = useContext(Context);
  const ref = useRef(null);

  const [light, setLight] = useState(
    context && context.filters.light ? context.filters.light : []
  );
  const [types, setTypes] = useState(
    context && context.filters.types ? context.filters.types : []
  );
  const [soilTypes, setSoilTypes] = useState(
    context && context.filters.soilTypes ? context.filters.soilTypes : []
  );
  const [zone, setZone] = useState(
    context && context.filters.zone ? context.filters.zone : ""
  );
  const [minHeight, setMinHeight] = useState(
    context && context.filters.minHeight ? context.filters.minHeight : 40
  );
  const [maxHeight, setMaxHeight] = useState(
    context && context.filters.maxHeight ? context.filters.maxHeight : 60
  );
  const [minSpread, setMinSpread] = useState(
    context && context.filters.minSpread ? context.filters.minSpread : 40
  );
  const [maxSpread, setMaxSpread] = useState(
    context && context.filters.maxSpread ? context.filters.maxSpread : 60
  );
  const [growthRate, setGrowthRate] = useState(
    context && context.filters.growthRate ? context.filters.growthRate : []
  );
  const [goodFor, setGoodFor] = useState(
    context && context.filters.goodFor ? context.filters.goodFor : []
  );
  const [filtered, setFiltered] = useState([]);

  const treeImages = context?.treeImages ?? {};

  const getImage = (id) => {
    const attachments = treeImages[id];
    return attachments ? attachments[0]?.thumbnails?.large.url : null;
  };

  const setData = () => {
    context.setFilters({
      light,
      types,
      soilTypes,
      zone,
      minHeight,
      maxHeight,
      minSpread,
      maxSpread,
      growthRate,
      goodFor,
      filtered,
    });
    setFiltered(
      trees.filter(({ fields }) => {
        if (fields["Scientific Name"] === "OPTIONS FOR FIELDS") return false;
        if (light.length > 0) {
          let included = true;
          light.forEach((type) => {
            if (
              !fields["Sun"] ||
              !fields["Sun"].toLowerCase().includes(type.toLowerCase())
            )
              included = false;
          });
          if (!included) return false;
        }
        if (growthRate.length > 0) {
          let included = true;
          growthRate.forEach((type) => {
            if (
              !fields["Growth Rate"] ||
              !fields["Growth Rate"].toLowerCase().includes(type.toLowerCase())
            )
              included = false;
          });
          if (!included) return false;
        }
        if (types.length > 0) {
          let included = true;
          types.forEach((type) => {
            if (
              !fields["Tree Types"] ||
              !fields["Tree Types"].toLowerCase().includes(type.toLowerCase())
            )
              included = false;
          });
          if (!included) return false;
        }
        if (soilTypes.length > 0) {
          let included = true;
          soilTypes.forEach((type) => {
            if (
              !fields["Soil Types"] ||
              !fields["Soil Types"].toLowerCase().includes(type.toLowerCase())
            )
              included = false;
          });
          if (!included) return false;
        }
        if (goodFor.length > 0) {
          let included = true;
          goodFor.forEach((type) => {
            if (
              !fields["Good For"] ||
              !fields["Good For"].toLowerCase().includes(type.toLowerCase())
            )
              included = false;
          });
          if (!included) return false;
        }
        if (zone) {
          if (!fields["Hardiness Zone"]) return false;
          const split = fields["Hardiness Zone"].split("-");
          if (split[1]) {
            if (+zone > +split[1] || +zone < +split[0]) return false;
          } else if (split[0] && +zone !== +split[0]) return false;
        }
        if (minHeight && maxHeight) {
          if (!fields["Height (Ft.)"]) return false;
          const split = fields["Height (Ft.)"].split("-");

          if (split[1]) {
            if (+maxHeight < +split[1] || +minHeight > +split[1]) return false;
          } else if (
            +split[0] &&
            (+minHeight > +split[0] || +maxHeight < +split[0])
          )
            return false;
        }
        if (minSpread && maxSpread) {
          if (!fields["Height (Ft.)"]) return false;
          const split = fields["Height (Ft.)"].split("-");
          if (split[1]) {
            if (+maxSpread < +split[1] || +minSpread > +split[1]) return false;
          } else if (
            +split[0] &&
            (+minSpread > +split[0] || +maxSpread < +split[0])
          )
            return false;
        }
        return true;
      })
    );
  };

  useEffect(() => {
    if (trees) setData();
  }, [trees]);

  const parseSun = (text) => {
    if (text.includes("Full")) return "Full";
    else if (text.includes("Partial")) return "Partial";
    else if (text.includes("Shade")) return "Shade";
  };

  const sort = (a, b) => {
    let comparison = 0;
    const nameOne = a.fields["Common Name"];
    const nameTwo = b.fields["Common Name"];
    if (nameTwo > nameOne) {
      comparison = -1;
    } else if (nameOne > nameTwo) {
      comparison = 1;
    }
    return comparison;
  };

  return (
    <section
      className={styles.section}
      id={block.template.replace("main-", "")}
      ref={ref}
    >
      <div className={styles.search}>
        <h1 className={styles.heading}>
          <Content text={block.blockTitle} />
        </h1>
        <LightSelector
          block={block}
          light={light}
          setLight={setLight}
          setData={setData}
        />
        <ZoneSelector setZone={setZone} setData={setData} zone={zone} />
        <TypeSelector
          block={block}
          types={types}
          setTypes={setTypes}
          setData={setData}
        />
        <SoilSelector
          block={block}
          soilTypes={soilTypes}
          setSoilTypes={setSoilTypes}
          setData={setData}
        />
        <HeightSlider
          minHeight={minHeight}
          maxHeight={maxHeight}
          setMinHeight={setMinHeight}
          setMaxHeight={setMaxHeight}
          setData={setData}
        />
        <SpreadSlider
          minSpread={minSpread}
          maxSpread={maxSpread}
          setMinSpread={setMinSpread}
          setMaxSpread={setMaxSpread}
          setData={setData}
        />
        <GrowthRateSelector
          growthRate={growthRate}
          setGrowthRate={setGrowthRate}
          setData={setData}
        />
        <GoodForSelector
          block={block}
          goodFor={goodFor}
          setGoodFor={setGoodFor}
          setData={setData}
        />
        <p className={styles.partnership}>{block.partnership}</p>
      </div>
      <div className={styles.padding} />
      <div className={styles.results}>
        <h2 className={styles.resultsHeading}>
          <Content text="Results" />
        </h2>
        {filtered.length === 0 ? (
          <p className={styles.none}>
            <Content text="Sorry, no such perfect tree" />
          </p>
        ) : null}
        {filtered.sort(sort).map(({ id, fields }, i) => (
          <Link
            key={`${id}-${fields["Common Name"]}-${i}`}
            to={`/${fields["Common Name"]
              .toLowerCase()
              .split("/")[0]
              .split(" ")
              .join("-")}`}
            className={styles.tree}
          >
            <div className={styles.imageContainer}>
              {getImage(id) ? (
                <img src={getImage(id)} className={styles.image} />
              ) : (
                <img src={"/placeholder.png"} className={styles.image} />
              )}
            </div>
            <h4 className={styles.name}>
              <Content text={fields["Common Name"]} />
            </h4>
            <p className={styles.sun}>
              <Content
                text={`Sun preference: ${
                  fields["Sun"] && parseSun(fields["Sun"])
                }`}
              />
            </p>
            <p className={styles.growth}>
              <Content
                text={`Growth rate: ${
                  fields["Growth Rate"] && fields["Growth Rate"]
                }`}
              />
            </p>
            <p className={styles.height}>
              <Content
                text={`Mature height: ${
                  fields["Height (Ft.)"] && fields["Height (Ft.)"] + " feet"
                }`}
              />
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
};
