import React, { useContext, useEffect, useRef } from 'react'
import { Context } from '../../context'
import Blocks from './components'

import * as styles from './main.module.scss'

// import {  } from '../../@types'

type mainProps = {
  children: [any] | undefined,
  scroll: number,
  trees?: object,
  articles?: [any],
  stories?: [any],
  scrollTo? : any
}

export default ({ blocks, scroll, children, trees, articles, stories, scrollTo }: mainProps) => {
  const context = useContext(Context);
  const ref = useRef(null);
  
  return context 
  ? (
    <main className={styles.main} id='main' ref={ref}>
      {/* {context.menuState ? <Blocks.menu /> : null} */}
        <div className={styles.blocks}>
          {blocks && blocks.length
            ? blocks.map((block, i) => {
              const index = Object.keys(Blocks).indexOf(block.template)
              const Block = index !== null && Object.values(Blocks)[index]
              return Block ? (
                <Block key={i} block={block} scroll={scroll} trees={trees} articles={articles} stories={stories} scrollTo={scrollTo}/>
              ) : (
                <p key={i} className={styles.title}>{block.template} does not exist</p>
              )
            })
            : null}
        </div>
      {children}
    </main>
  )
  : null
}