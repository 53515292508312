export default (label, option, selectOpen, setSelectOpen, setFormValues) => {
  if (!selectOpen) {
    setSelectOpen(true);
  } else {
    setFormValues(values => {
      const copy = {...values};
      copy[label] = option;
      return copy;
    });
    setSelectOpen(false);
  }
}