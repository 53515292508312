import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../context'
import * as styles from './menu.module.scss'
import { checkEmail, ForestryLink, getTranslation, Content } from '../../../../utilities'

export default ({ block }) => { 
  const context = useContext(Context);
  const [placeholder, setPlaceholder] = useState('email address');
  const [firstPlaceholder, setFirstPlaceholder] = useState('first name');
  const [lastPlaceholder, setLastPlaceholder] = useState('last name');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const translateText = async () => {
      if (context && context.language !== 'en') {
        const result = await getTranslation('email address', context.language);
        if (result['data'][0]) {
          setPlaceholder(result['data'][0]);
        }
        const resultTwo = await getTranslation('first name', context.language);
        if (resultTwo['data'][0]) {
          setPlaceholder(result['data'][0]);
        }
        const resultThree = await getTranslation('last name', context.language);
        if (resultThree['data'][0]) {
          setPlaceholder(result['data'][0]);
        }
      } else {
        setPlaceholder('email address');
        setFirstPlaceholder('first name');
        setLastPlaceholder('last name');
      }
    }
    translateText();
  }, [context && context.language])

  const checkInputs = () => {
    return firstName && lastName
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (!checkEmail(email) || !checkInputs) {
      setMessage('Enter valid inputs.')
    } else {
      setMessage('');
      fetch('/.netlify/functions/mailerlite', {
        method: 'POST',
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          groupId: block.submitTo.split('/')[1]
        })
      })
      .then(response => {
        setMessage('Thank you for signing up!');
        setEmail('');
        setFirstName('');
        setLastName('');
        // console.log(response)
      })
      .catch(error => {
        setMessage('There was an error processing your request.');
      })
    }
  }

  useEffect(() => {
    setMessage('');
    setEmail('');
    setFirstName('');
    setLastName('');
  }, [context && context.menuOpen])

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'email') {
      setEmail(value);
    }
    else if (name === 'firstName') {
      setFirstName(value);
    }
    else if (name === 'lastName') {
      setLastName(value);
    }
  }

  return (
    <section className={styles.section + ` ${context && context.menuOpen ? `${styles.open}` : ''}`}>
      {/* <div className={styles.searchBackground}></div> */}
      
      <div className={styles.container}>
        
        {block.mainLinks.map(link => (
          <ForestryLink 
            key={link.label}
            link={link}
            className={styles.mainLink}
            activeClassName={styles.active}
            onClick={() => {
              if (context) {
                context.setMenuOpen(false);
                context.setSearch('');
              }
            }}
          />
        ))}
        <div className={styles.space} />
        {block.secondaryLinks.map(link => (
          link.linkType === 'page' ? (
            <ForestryLink
              key={link.label}
              link={link}
              className={styles.secondaryLink}
              activeClassName={styles.active}
              onClick={() => {
                if (context) {
                  context.setMenuOpen(false);
                  context.setSearch('');
                }
              }}
            />
          ) : <a href={link.url} className={styles.secondaryLink}>
                <Content text={link.label} />
              </a>
        ))}
        <p className={styles.footerHeading}><Content text={block.footerHeading} /></p>
        <p className={styles.footerSubheading}><Content text={block.footerSubheading} /></p>
        {message &&
        <p className={styles.error}>{message}</p>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <input 
            className={styles.input}
            placeholder={placeholder}
            value={email}
            onChange={handleChange}
            name='email'
            />
          <input 
            className={styles.input}
            placeholder={firstPlaceholder}
            value={firstName}
            onChange={handleChange}
            name='firstName'
            />
          <input 
            className={styles.input}
            placeholder={lastPlaceholder}
            value={lastName}
            onChange={handleChange}
            name='lastName'
            />
          <button className={styles.button} style={{backgroundImage: `url('/icon-light-green.svg')`}}>
            <img src='/arrow-right-dark-green.svg' />
          </button>
        </form>
      </div>
    </section>
  )
}