import React, { useEffect, useRef, useState} from 'react'
import * as styles from './footer.module.scss'
import { Content } from '../../../../../../utilities'
import { Link } from 'gatsby'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div
        className={styles.container}
        style={{backgroundImage: `url(${block.footerBackground})`}}>
          <div className={styles.quote}>
            <Content text={block.footerQuote} />
          </div>
          <div className={styles.name}>
            <Content text={block.footerName} />
          </div>
      </div>
      <a
        href={block.masterPlanFile}
        target='_blank'
        rel='noreferrer'
        className={styles.button}
      >
        <Content text={block.bottomButtonText} /><span><img src='/button.svg' className={styles.icon} /></span>
      </a>
    </section>
  )
}