import React, { useEffect, useState, useRef } from 'react'
import * as styles from './heading.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ heading, subheading, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .9)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.heading + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <h2 className={styles.headingText}>
        <Content text={heading} />
      </h2>
      <h4 className={styles.subheading}>
        <Content text={subheading} />
      </h4>
    </div>
  )
}