import React, { useState, useEffect, useRef, useContext } from 'react'
import * as styles from './simpleText.module.scss'
import { Context } from '../../../../context'
import { Content, Leaf } from '../../../../utilities'

export default ({ block, scroll }) => {
  const ref = useRef(null);
  const context = useContext(Context);
  const [show, setShow] = useState(false);
  const [season, setSeason] = useState('summer');
  const [mobile, setMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 767 ? true :
    false
  )

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .8)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 767)
        setMobile(false);
      else setMobile(true);
    }
    resize();

    window.addEventListener('resize', resize);

    return (() => {
      window.removeEventListener('resize', resize);
    })
  }, [])

  useEffect(() => {
    if (context && context.season) {
      setSeason(context.season);
    }
  }, [context && context.season])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      {block.leafOne && block.leafOne.summer && block.leafOne.fall &&
      <Leaf
        width={'19vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafOne.fall : block.leafOne.summer}
        top={-10}
        right={mobile ? -2 : -10}
        speed={7}
        direction={'up'}
      />}
      {block.leafTwo && block.leafTwo.summer && block.leafTwo.fall &&
      <Leaf 
        width={mobile ? '29vw' : '19vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafTwo.fall : block.leafTwo.summer}
        top={mobile ? -20 : -10}
        right={mobile ? -1 : 13}
        speed={mobile ? 7 : 8}
        direction={'down'}
      />}
      {block.leafThree && block.leafThree.summer && block.leafThree.fall &&
      <Leaf 
        width={mobile ? '25vw' : '19vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafThree.fall : block.leafThree.summer}
        top={mobile ? 70 : 30}
        right={mobile ? -8 : -5}
        speed={mobile ? 20 : 12}
        direction={'down'}
      />}
      {block.leafFour && block.leafFour.summer && block.leafFour.fall &&
      <Leaf
        width={mobile ? '38vw' : '19vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafFour.fall : block.leafFour.summer}
        bottom={20}
        left={1}
        speed={10}
        zIndex={2}
        direction={'up'}
      />}
      {block.leafFive && block.leafFive.summer && block.leafFive.fall &&
      <Leaf
        width={mobile ? '22vw' : '11vw'}
        scroll={scroll}
        image={season === 'fall' ? block.leafFive.fall : block.leafFive.summer}
        bottom={mobile ? 5 : 15}
        left={mobile ? 2 : 10}
        speed={3}
        // zIndex={-1}
        direction={'down'}
      />}
      <div className={styles.left}>
        <div className={styles.heading}>
          <Content text={block.blockTitle} />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.container}>
          <div className={styles.body}>
            <Content text={block.body} />
          </div>
          {block.icons &&
          <div className={styles.icons}>
            {block.icons.map(({icon}) => (
              <img key={icon} src={icon} className={styles.icon} />
            ))}
          </div>}
        </div>
      </div>
    </section>
  )
}