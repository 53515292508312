import React, { useState, useEffect, useRef } from 'react'
import * as styles from './team.module.scss'
import { Content } from '../../../../utilities'


export default ({ block, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    fetch('/.netlify/functions/staff', {
      method: 'POST',
    })
    .then(res => res.json())
    .then(response => {
      if (response.data) {
        // console.log(response.data)
        const list = [];
        const filtered = response.data.filter(member => member.fields.Name);
        block.teamMembers.forEach(member => {
          const foundMember = filtered.find(teamMember => (
            teamMember.fields.Name === member.name
          ));
          if (foundMember) {
            list.push(foundMember);
          }
        })
        setStaffList(list);
      }
    })
  }, [])

  const colors = ['#067BC2', '#BFD15B', '#32B44A'];

  const getColor = i => {
    if (i <= 2)
      return colors[i];
    else if (i % 3 === 0)
      return colors[0];
    else if ((i - 1) % 3 === 0)
      return colors[1];
    else
      return colors[2];
  }

  const handleEnter = i => {
    if (window.innerWidth > 767) {
      setCurrent(i);
    }
  }

  const handleLeave = () => {
    if (window.innerWidth > 767) {
      setCurrent(null);
    }
  }

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      <h2 className={styles.heading}>
        <Content text={block.blockTitle} />
      </h2>
      <div className={styles.container}>
        {staffList.map(({fields}, i) => (
          <div 
            key={fields.Name}
            className={styles.member}
            style={{backgroundColor: getColor(i), transitionDelay: `.${i}s`}}
            onMouseEnter={() => handleEnter(i)}
            onMouseLeave={handleLeave}
            onClick={() => setCurrent(current === i ? null : i)}
          >
            {fields["Field Photo "] && fields["Field Photo "][0] && fields["Photo"] && fields["Photo"][0]
            ? (
              <img src={
                current === i ? fields["Field Photo "][0].thumbnails.large.url :
                fields["Photo"][0].thumbnails.large.url
              } className={styles.image} />
            ) 
            : fields["Photo"] && fields["Photo"][0] ? (
              <img src={fields["Photo"][0].thumbnails.large.url
              } className={styles.image} />
            )
          : fields["Field Photo "] && fields["Field Photo "][0] ? (
            <img src={fields["Field Photo "][0].thumbnails.large.url
            } className={styles.image} />
          )
          : null
      }
            <div 
              className={styles.gradient}
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(191, 209, 91, 0) 0%, ${getColor(i)} 100%)`
              }}
            />
            {/* {current === i && fields["Include Email on Website"] ? (
              <div className={styles.buttons}>
                <a href={`mailto:${fields["Email address"]}`}>
                  {fields["Email address"]}
                </a>
              </div>
            ) : ( */}
              <div className={styles.text}>
                <p className={styles.name}>{fields.Name}</p>
                <p className={styles.title}>
                  <Content text={fields.Title} />
                </p>
                {fields['Include Email on Website'] ? (
                <p className={styles.title}><a href={`mailto:${fields["Email address"]}`}>
                  {fields["Email address"]}
                </a></p>) : <p className={styles.padding}></p>}
              </div>
            {/* )} */}
          </div>
        ))}
      </div>
    </section>
  )
}