import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../context'
import * as styles from './languageButton.module.scss'
import Languages from '../../../../settings/languages.json'

export default () => { 
  const context = useContext(Context);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (context && context.language) {
      const filtered = Languages.languages.find(language => language.code === context.language);
      if (filtered.flag) {
        setLanguage(filtered);
      }
    }
  }, [context && context.language])


  return (
    <button
      className={styles.section + ` ${context && context.languagesOpen ? `${styles.open}` : ''}`}
      onClick={() => context && context.setLanguagesOpen(!context.languagesOpen)}
      style={{
        backgroundImage: `url(${context && context.languagesOpen ? '/x.svg' : language && language.flag})`,
        zIndex: context && context.languagesOpen ? 99 : 0
      }}
    />
  )
}