// extracted by mini-css-extract-plugin
export var active = "education-module--active--6pXEF";
export var category = "education-module--category--kxfHI";
export var categoryBtn = "education-module--categoryBtn--UTIfY";
export var chevron = "education-module--chevron--LHRmg";
export var dropOpen = "education-module--dropOpen--h-rAO";
export var fill = "education-module--fill--37XAO";
export var hasSubcategories = "education-module--hasSubcategories--bwwn4";
export var leaves = "education-module--leaves--409-l";
export var left = "education-module--left--4g6dF";
export var open = "education-module--open--HCXuY";
export var padding = "education-module--padding--4lVwy";
export var right = "education-module--right--UUPex";
export var section = "education-module--section--HK6XF";
export var show = "education-module--show--a0SfH";
export var stick = "education-module--stick--Bt8Gf";
export var subOpen = "education-module--subOpen--RUYPb";
export var subcategories = "education-module--subcategories--2+kyZ";
export var subcategory = "education-module--subcategory--w8B0N";