import React from 'react'
import * as styles from './slideoutForm.module.scss'
import FooterForm from '../../layout/main/components/footerForm'

export default ({ block, title=null, buttonText=null, formOpen, setFormOpen }) => {

  return (
    <div className={styles.slideout + ` ${formOpen ? `${styles.open}` : ''}`}>
      <div className={styles.container}>
        <button
          className={styles.close}
          style={{backgroundImage: `url(/x-green.svg)`}}
          onClick={() => setFormOpen(false)}
        />
        <FooterForm block={block} title={title} buttonText={buttonText} scroll={1000000} />
      </div>
    </div>
  )


}