// extracted by mini-css-extract-plugin
export var bottom = "availableSpecies-module--bottom--kxUjH";
export var buttonContainer = "availableSpecies-module--buttonContainer--zLWLa";
export var buttons = "availableSpecies-module--buttons--+DagB";
export var customPadding = "availableSpecies-module--customPadding--Qo915";
export var dark = "availableSpecies-module--dark--N0FQR";
export var footerText = "availableSpecies-module--footerText--Nrhhg";
export var growth = "availableSpecies-module--growth--mG+Rx";
export var heading = "availableSpecies-module--heading--f93Nu";
export var height = "availableSpecies-module--height--y3Jj8";
export var hide = "availableSpecies-module--hide--OLbwv";
export var image = "availableSpecies-module--image--nRqq5";
export var imageContainer = "availableSpecies-module--imageContainer--prRcs";
export var left = "availableSpecies-module--left--Wtl1Q";
export var leftIcon = "availableSpecies-module--leftIcon--oISsX";
export var name = "availableSpecies-module--name--iisdX";
export var noPadding = "availableSpecies-module--noPadding--7JLff";
export var padding = "availableSpecies-module--padding--LaPyQ";
export var right = "availableSpecies-module--right--Y2hWr";
export var rightIcon = "availableSpecies-module--rightIcon--Q4Gwc";
export var section = "availableSpecies-module--section--OxBfb";
export var show = "availableSpecies-module--show--T0wiq";
export var small = "availableSpecies-module--small--rh+dA";
export var sun = "availableSpecies-module--sun--pNTdS";
export var tree = "availableSpecies-module--tree--ujaBo";
export var trees = "availableSpecies-module--trees--YR5jm";