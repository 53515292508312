// extracted by mini-css-extract-plugin
export var container = "keystones-module--container--99+IT";
export var heading = "keystones-module--heading--9--S2";
export var headingContainer = "keystones-module--headingContainer--6VnV+";
export var icon = "keystones-module--icon--ch+vk";
export var keystone = "keystones-module--keystone--Aa6xb";
export var keystones = "keystones-module--keystones--vTrJ0";
export var label = "keystones-module--label--mdm8p";
export var link = "keystones-module--link--knP-C";
export var numContainer = "keystones-module--numContainer--uLM44";
export var number = "keystones-module--number--pdq2a";
export var orange = "keystones-module--orange--L6H9w";
export var section = "keystones-module--section--2oeEf";
export var show = "keystones-module--show--VEo8b";
export var text = "keystones-module--text--u0Whc";