// extracted by mini-css-extract-plugin
export var active = "store-module--active--nMPjZ";
export var amount = "store-module--amount--V6Zez";
export var cartIcon = "store-module--cartIcon--Wh9CS";
export var categories = "store-module--categories--DPFPa";
export var category = "store-module--category--E2lnS";
export var green = "store-module--green--v2ycU";
export var image = "store-module--image--8yKNY";
export var inCart = "store-module--inCart--M-g6-";
export var inCartContainer = "store-module--inCartContainer--BPIXQ";
export var product = "store-module--product--pMOUN";
export var products = "store-module--products--ZQxnJ";
export var section = "store-module--section--6TK70";
export var show = "store-module--show--toq3V";
export var title = "store-module--title--gWZ8T";