import React, { useState, useEffect, useContext, useRef } from 'react'
import * as styles from './hero.module.scss'
import SharedTextButton from '../sharedTextButton'
import FooterForm from '../footerForm'
import { Content, SlideoutForm } from '../../../../utilities'
import { Context } from '../../../../context'
import content from '../../../../utilities/content'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const context = useContext(Context);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100)
  }, [])

  useEffect(() => {
    if (context && ref.current) {
      context.setHeroHeight(ref.current.offsetHeight);
    }
  }, [ref.current])

  const handleClick = () => {
    if (block.button && block.button.linkType === 'form' && block.button.form && block.button.form === 'donate') {
      if (block.formSubmitTo) {
        context.setDonateUrl(block.formSubmitTo)
      } else {
        context.setDonateUrl('')
      }
      context.setDonateOpen(true);
    } else {
      setFormOpen(!formOpen);
    }
  }

  return (
    <section className={styles.section} ref={ref} id={block.template.replace('main-', '')}>
      <img 
        src={block.image} 
        className={styles.image + ` ${show ? `${styles.show}` : ''}`} 
      />
      <div 
        className={styles.background + ` ${show ? `${styles.show}` : ''}`} 
        style={{backgroundColor: block.color}}
      >
        <div className={styles.heading + ` ${block.blockTitle.length > 125 ? `${styles.small}` : ''}`}>
          <Content text={block.blockTitle} />
        </div>
        {block.subheading &&
        <div className={styles.subheading}>
          <Content text={block.subheading} />
        </div>}
      </div>
      <div className={styles.buttonContainer + ` ${show ? `${styles.show}` : ''}`}>
        <SharedTextButton button={block.button} onClick={handleClick} />
      </div>
      {block.inputs && block.inputs.length > 0 &&
      <SlideoutForm block={block} formOpen={formOpen} setFormOpen={setFormOpen} />}
      
    </section>
  )
}