import React, { useContext, useState, useRef, useEffect } from 'react'
import * as styles from './store.module.scss'
import { Link } from 'gatsby'
import { parseAmount, Cart, Content } from '../../../../utilities'
import { Context } from '../../../../context'

export default ({ block, products, scroll }) => {
  const context = useContext(Context)
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [active, setActive] = useState('All products');
  const [filtered, setFiltered] = useState(products);
  const [checkoutItems, setCheckoutItems] = useState(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    if (context && context.checkout) {
      setCheckoutItems(context.checkout.lineItems);
    }
  }, [context && context.checkout])

  useEffect(() => {
    if (active === 'All products')
      setFiltered(products)
    else if (active === 'Tees')
      setFiltered(products.filter(({node}) => node.productType === 'Shirts'))
    else if (active === 'Headwear')
      setFiltered(products.filter(({node}) => node.productType === 'Headwear'))
    else if (active === 'Sweaters')
      setFiltered(products.filter(({node}) => node.productType === 'Outerwear'))
  }, [active])

  const ifAdded = id => {
    if (checkoutItems) {
      const variantId = id.split('Shopify__ProductVariant__')[1];
      const item = checkoutItems.find(item => item.variant.id === variantId);
      if (item) {
        return true;
      }
      else return false;
    }
  }

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template.replace('main-', '')}>
      <div className={styles.categories}>
        {block.categories.map(category => (
          <button
            key={category}
            className={styles.category + ` ${active === category ? `${styles.active}` : ''}`}
            onClick={() => setActive(category)}
          >
              <Content text={category} />
          </button>
        ))}
        <Cart />
      </div>
      <div className={styles.products}>
        {filtered.map(({ node }) => (
          <Link 
            key={node.handle}
            to={node.handle}
            className={styles.product + ` ${ifAdded(node.variants[0].id) ? `${styles.active}` : ''}`}
          >
            <img src={node.images[0].originalSrc} className={styles.image} />
            <p className={styles.amount}>
              ${parseAmount(node.priceRange.maxVariantPrice.amount)}
            </p>
            <p className={styles.title}>
              <Content text={node.title} />
            </p>
            <div className={styles.inCartContainer}>
              <div className={styles.inCart}>
                <img src='/button-green.svg' className={styles.green} />
                <img src='/shopping-cart-white.svg' className={styles.cartIcon} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
  