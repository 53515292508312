// extracted by mini-css-extract-plugin
export var challenge = "challenges-module--challenge--rqk+v";
export var container = "challenges-module--container--38Kbo";
export var heading = "challenges-module--heading--b-hB-";
export var headingContainer = "challenges-module--headingContainer--tm5Vr";
export var icon = "challenges-module--icon--aNs3w";
export var items = "challenges-module--items--of1yV";
export var numContainer = "challenges-module--numContainer---0L8t";
export var number = "challenges-module--number--sCnON";
export var orange = "challenges-module--orange--9iIid";
export var section = "challenges-module--section--PoFWL";
export var show = "challenges-module--show--bFBTR";