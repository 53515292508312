import React, { useRef, useState, useEffect } from 'react'
import Blocks from '../../settings/footer.json'
import Components from './components'
import * as styles from './footer.module.scss'

export default ({ scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top <= (window.innerHeight * .75)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().top <= (window.innerHeight * .75)) {
      setShow(true);
    }
  }, [])

  return (
    <footer className={styles.footer} ref={ref}>
      <div className={styles.container}>
        {Blocks.footerBlocks.map(block => (
          block.template === 'footer-main-pages' ? (
            <Components.MainPages key={block.template} block={block} show={show} />
          ) : block.template === 'footer-amazon' ? (
            <Components.Amazon key={block.template} block={block} show={show} />
          ) : block.template === 'footer-contact-form' ? (
            <Components.ContactForm key={block.template} block={block} show={show} />
          ) : block.template === 'footer-contact-info' ? (
            <Components.ContactInfo key={block.template} block={block} show={show} />
          ) : block.template === 'footer-copyright' ? (
            <Components.Copyright key={block.template} block={block} show={show} />
          ) : block.template === 'footer-involvement-pages' ? (
            <Components.InvolvementPages key={block.template} block={block} show={show} />
          ) : block.template === 'footer-social' ? (
            <Components.Social key={block.template} block={block} show={show} />
          ) : null
        ))}
      </div>
      <div className={styles.bottom + ` ${show ? `${styles.show}` : ''}`}>
        <div className={styles.green} />
        <div className={styles.orange} />
        <div className={styles.blue} />
      </div>
    </footer>
  )
}