// extracted by mini-css-extract-plugin
export var arrow = "canopyGoal-module--arrow--knBYH";
export var body = "canopyGoal-module--body--DOFo8";
export var bottom = "canopyGoal-module--bottom--8Yn6R";
export var curvyArrow = "canopyGoal-module--curvyArrow--JJPB7";
export var heading = "canopyGoal-module--heading--TG-Q5";
export var map = "canopyGoal-module--map--+VtQM";
export var mapGroup = "canopyGoal-module--mapGroup--UCQpk";
export var maps = "canopyGoal-module--maps--jSlhu";
export var numbers = "canopyGoal-module--numbers--oAWFr";
export var section = "canopyGoal-module--section--rjb86";
export var show = "canopyGoal-module--show--3ZJuL";
export var subheading = "canopyGoal-module--subheading---yFQl";