import React, { useEffect, useRef, useState} from 'react'
import * as styles from './benchmarks.module.scss'
import { Content } from '../../../../../../utilities'
import { Link } from 'gatsby'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.headingContainer}>
        <div className={styles.numContainer}>
          <img src={'./button-orange.svg'} className={styles.orange} />
          <p className={styles.number}>2</p>
        </div>
        <h2 className={styles.heading}>
          <Content text={block.benchMarksHeading} />
        </h2>
      </div>
      <div className={styles.benchMarkGroups}>
        {block.benchMarkGroups.map((benchmarks, i) => (
          <div key={i} className={styles.benchmarks}>
            {benchmarks.label &&
              <h4 className={styles.label}>
                <Content text={benchmarks.label} />
              </h4>}
            <div className={styles.container}>
              {benchmarks.benchmarks.map((benchmark, index) => (
                <div
                  key={benchmark.bottomText}
                  className={styles.benchmark}
                  style={{ transitionDelay: `.${i + index}s`}}>
                  {benchmark.topText &&
                    <p className={styles.topText + ` ${index === 0 ? `${styles.indent}` : ''}`}>
                      <Content text={benchmark.topText} />
                    </p>}
                    <div className={styles.imageContainer}>
                      <img src={benchmark.numberImage} className={styles.image} />
                    </div>
                    <p className={styles.bottomText + ` ${benchmark.bottomText.length <= 15 ? `${styles.narrow}` : ''}`}>
                      <Content text={benchmark.bottomText} />
                    </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}