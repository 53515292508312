import React from 'react'
import * as styles from './sharedTextButton.module.scss'
import { ForestryLink, Content } from '../../../../utilities'

type SharedTextButton = {
  button: any,
  link?: string,
  onClick?: any
}

export default ({ button, link, onClick } : SharedTextButton) => {

  return (
    ['page'].includes(button.linkType) ? (
      <ForestryLink 
        link={button}
        customLink={link ? link : null}
        className={styles.button + ` ${styles[button.color]}`}
        onClick={onClick}
      >
        <span className={button.label.length > 40 ? `${styles.small}` : ''}>
          <Content text={button.label} />
        </span>
        
        <span className={styles.iconContainer}><img src='/button.svg' className={styles.icon} /></span>
      </ForestryLink>
    ) : button.linkType === 'external url' ? (
      <a
        className={styles.button + ` ${styles[button.color]}`}
        onClick={onClick}
        href={button.externalUrl}
        target='_blank'
        rel='noreferrer'
      >
        <span><Content text={button.label} /></span>
        <span className={styles.iconContainer}><img src='/button.svg' className={styles.icon} /></span>
      </a>
    ) : <button
          className={styles.button + ` ${styles[button.color]}`}
          onClick={onClick}
        >
          <span><Content text={button.label} /></span>
          <span className={styles.iconContainer}><img src='/button.svg' className={styles.icon} /></span>
        </button>
  )
}