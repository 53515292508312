import React, { useState, useEffect, useRef } from 'react'
import * as styles from './leaf.module.scss'

export default ({ 
  image,
  scroll, 
  width,
  top = null,
  bottom = null,
  left = null,
  right = null,
  speed = 5,
  zIndex = 1,
  direction,
  translate=true
}) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .8)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    const move = e => {
      setX(e.clientX);
      setY(e.clientY);
    }

    document.addEventListener('mousemove', move);

    return (() => {
      document.removeEventListener('mousemove', move);
    })
  }, [])

  return (
    <img 
      src={image} 
      className={styles.leaf + ` ${show ? `${styles.show}` : ''}`}
      ref={ref}
      style={{ 
        width: width,
        top: `${top ? `${top}vw` : 'unset'}`,
        bottom: `${bottom ? `${bottom}vw` : 'unset'}`,
        left: `${left ? `${left}vw` : 'unset'}`,
        right: `${right ? `${right}vw` : 'unset'}`,
        zIndex: zIndex,
        transform: `${translate ? `translateY(${direction === 'down' ? '-' : ''}${(ref.current && ref.current.getBoundingClientRect().top * .03 * speed + (y * .02))}px) translateX(${direction === 'up' ? '-' : ''}${x * .02}px)` : ''}`
      }}
    />
  )
}  