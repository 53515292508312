import React, { useState, useEffect, useRef } from "react";
import * as styles from "./homeOptionChanger.module.scss";
import SharedTextButton from "../sharedTextButton";
import { Link } from "gatsby";
import { Content } from "../../../../utilities";

export default ({ block, scroll }) => {
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      scroll &&
      ref.current &&
      ref.current.getBoundingClientRect().top < window.innerHeight * 0.5
    ) {
      setShow(true);
    }
  }, [scroll]);

  const handleClick = (i) => {
    setShow(false);
    setCurrent(i);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };

  return (
    <section
      className={styles.section}
      id={block.template.replace("main-", "")}
    >
      <div className={styles.container}>
        <div className={styles.options}>
          {block.options.map((option, i) => (
            <button
              key={option.label}
              className={
                styles.option + ` ${current === i ? `${styles.current}` : ""}`
              }
              onClick={() => handleClick(i)}
            >
              <Content text={option.label} />
            </button>
          ))}
        </div>
        <div
          className={styles.middle + ` ${show ? `${styles.show}` : ""}`}
          ref={ref}
        >
          <div className={styles.imgContainer}>
            {block.options[current] && block.options[current].imageOne && (
              <img
                src={block.options[current].imageOne}
                className={styles.imageOne}
              />
            )}
            <div className={styles.overlay} />
          </div>
          {block.options[current] && block.options[current].button && (
            <div className={styles.button}>
              <SharedTextButton button={block.options[current].button} />
            </div>
          )}
          {block.options[current] && block.options[current].linkList && (
            <ul className={styles.list + ` ${show ? `${styles.show}` : ""}`}>
              {block.options[current].linkList.map((link) => (
                <li key={link.label}>
                  <Link to={link.link} className={styles.link}>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.right + ` ${show ? `${styles.show}` : ""}`}>
          {block.options[current] && block.options[current].imageTwo && (
            <img
              src={block.options[current].imageTwo}
              className={styles.imageTwo}
            />
          )}
        </div>
      </div>
    </section>
  );
};
