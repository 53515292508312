import React, { useEffect, useRef, useState} from 'react'
import * as styles from './header.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 200)
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.left}>
        <h1 className={styles.heading}>
          <Content text={block.blockTitle} />
        </h1>
        <div className={styles.subheading}>
          <Content text={block.subheading} />
        </div>
      </div>
      <div className={styles.right}>
        <img src={block.topImage} className={styles.image} />
      </div>
    </section>
  )
}