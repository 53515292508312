export default async (text, language) => {
  let response = await fetch('/.netlify/functions/translate', {
    method: 'POST',
    body: JSON.stringify({
      text,
      language
    })
  })
  response = await response.json();
  return response;
}