import React from 'react'
import { Content } from './'
import parse from 'html-react-parser'

export default ({ text, classname }) => {
  if (text && !text.includes('<em>')) {
    if (text.length < 150)
      return <div className={classname}><Content text={text} /></div>
    else {
      for (let i = 150; i < text.length; i++) {
        if (text[i] === ' ')
          return <div className={classname}><Content text={text.slice(0, i + 1) + '...'} /></div>
      }
    }
  }
  
  if (text) {
    let firstPeriod = null;
    let wordStart = null;
    let wordEnd = null;
    let trimStart = 0;
    let trimEnd = text.length;
    for (let i = 0; i < text.length; i++) {
      if (['.','?','!'].includes(text[i]) && !wordEnd)
        firstPeriod = i + 1;
      if ((text[i] + text[i + 1] + text[i + 2]) === '<em') {
        wordStart = i;
      } else if ((text[i - 2] + text[i - 1] + text[i]) === '/em') {
        wordEnd = i + 1;
      } else if (wordEnd && ['.','?','!'].includes(text[i]) && i - trimStart > 150) {
        trimEnd = i;
        break;
      }
      if (wordStart > 150)
        trimStart = firstPeriod;
    }
    let prefix = trimStart > 0 ? '...' : '';
    let suffix = trimEnd < text.length ? '...' : '';
    const string = prefix + text.slice(trimStart, trimEnd) + suffix;
    return <div className={classname}><Content text={string} /></div>
  }
  return null
}