import React, { useState, useRef, useEffect, useContext } from "react";
import EducationContent from "../educationContent";
import * as styles from "./education.module.scss";
import { Content, Leaf } from "../../../../utilities";
import { Context } from "../../../../context";

export default ({ block, scroll, scrollTo }) => {
  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubcategory, setActiveSubcategory] = useState("");
  const [subcategoryOpen, setSubcategoryOpen] = useState(true);
  const [season, setSeason] = useState("summer");
  const [show, setShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [stick, setStick] = useState(false);
  const ref = useRef(null);
  const selectors = useRef(null);
  const right = useRef(null);
  const context = useContext(Context);
  const [dropOpen, setDropOpen] = useState(false);
  const [mobile, setMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= 767 ? true : false
  );

  const handleCategoryClick = (category) => {
    if (category.hasSubcategories) {
      setSubcategoryOpen(true);
    } else setSubcategoryOpen(false);
    setContentShow(false);
    setActiveCategory(category.blockTitle);
    setTimeout(() => {
      setContentShow(true);
    }, 100);
    window.scrollTo(0, context.heroHeight);
    setDropOpen(!dropOpen);
  };

  const handleSubcategoryClick = (title) => {
    setContentShow(false);
    setActiveSubcategory(title);
    setTimeout(() => {
      setContentShow(true);
    }, 100);
    if (context && typeof window !== "undefined") {
      window.scrollTo(0, context.heroHeight);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.location.search) {
      const split = window.location.search.split("=");
      if (split.length > 2) {
        setActiveCategory(split[1].split("%20").join(" ").replace("&sub", ""));
        setActiveSubcategory(split[2].split("%20").join(" "));
      } else {
        setActiveCategory(split[1].split("%20").join(" "));
        setActiveSubcategory(
          block.categories && block.categories[0]
            ? block.categories[0].subcategories[0].blockTitle
            : ""
        );
      }
    } else {
      setActiveCategory(
        block.categories && block.categories[0]
          ? block.categories[0].blockTitle
          : ""
      );
      setActiveSubcategory(
        block.categories && block.categories[0]
          ? block.categories[0].subcategories[0].blockTitle
          : ""
      );
    }
  }, []);

  useEffect(() => {
    if (
      scroll &&
      ref.current &&
      ref.current.getBoundingClientRect().top < window.innerHeight * 0.5
    ) {
      setShow(true);
      setContentShow(true);
      if (
        window.innerWidth > 767 &&
        ref.current.getBoundingClientRect().top <= window.innerWidth * 0.03
      ) {
        setStick(true);
      } else if (
        window.innerWidth <= 767 &&
        ref.current.getBoundingClientRect().top <= window.innerWidth * 0.08
      ) {
        setStick(true);
      } else setStick(false);
    }
  }, [scroll]);

  useEffect(() => {
    const onWheel = (e) => {
      if (context) {
        context.setMouseWheel(e.deltaY);
      }
    };

    if (selectors.current) {
      selectors.current.addEventListener("wheel", onWheel);
    }
    return () => {
      if (selectors.current) {
        selectors.current.removeEventListener("wheel", onWheel);
      }
    };
  }, []);

  useEffect(() => {
    if (context && context.season) {
      setSeason(context.season);
    }
  }, [context && context.season]);

  return (
    <section
      className={styles.section}
      ref={ref}
      id={block.template.replace("main-", "")}
    >
      <div
        className={
          styles.left +
          ` ${stick ? `${styles.stick}` : ""}` +
          ` ${dropOpen ? `${styles.dropOpen}` : ""}` +
          ` ${subcategoryOpen ? `${styles.subOpen}` : ""}`
        }
        ref={selectors}
      >
        {block.categories.map((category, i) => (
          <div
            key={`${category.blockTitle}${i}`}
            className={
              styles.category +
              ` ${
                activeCategory === category.blockTitle ? `${styles.active}` : ""
              }` +
              ` ${stick ? `${styles.stick}` : ""}`
            }
          >
            <button
              className={
                styles.categoryBtn +
                ` ${
                  activeCategory === category.blockTitle
                    ? `${styles.active}`
                    : ""
                }` +
                ` ${
                  category.hasSubcategories ? `${styles.hasSubcategories}` : ""
                }`
              }
              onClick={() => handleCategoryClick(category)}
            >
              <Content text={category.blockTitle} />
            </button>
            {category.hasSubcategories ? (
              <div
                className={
                  styles.subcategories +
                  ` ${
                    activeCategory === category.blockTitle
                      ? `${styles.open}`
                      : ""
                  }`
                }
              >
                {category.subcategories.map((subcategory, i) => (
                  <button
                    key={subcategory.category}
                    className={
                      styles.subcategory +
                      ` ${
                        activeSubcategory === subcategory.category ||
                        (!activeSubcategory && i === 0)
                          ? `${styles.active}`
                          : ""
                      }`
                    }
                    onClick={() => handleSubcategoryClick(subcategory.category)}
                  >
                    <Content text={subcategory.category} />
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        ))}
        <img
          src="chevron-down-1.svg"
          className={styles.chevron + ` ${stick ? `${styles.stick}` : ""}`}
          onClick={() => setDropOpen(!dropOpen)}
        />
        <div className={styles.leaves}>
          {block.leafOne && block.leafOne.fall && block.leafOne.summer && (
            <Leaf
              width={mobile ? "18vw" : "14vw"}
              scroll={scroll}
              image={
                season === "fall" ? block.leafOne.fall : block.leafOne.summer
              }
              top={mobile ? -38 : 1}
              left={mobile ? 75 : 5}
              speed={7}
              direction={"down"}
              translate={false}
            />
          )}
          {block.leafTwo && block.leafTwo.fall && block.leafTwo.summer && (
            <Leaf
              width={mobile ? "0vw" : "14vw"}
              scroll={scroll}
              image={
                season === "fall" ? block.leafOne.fall : block.leafOne.summer
              }
              top={12}
              left={2}
              speed={8}
              direction={"up"}
              translate={false}
            />
          )}
        </div>
      </div>
      <div className={styles.fill + ` ${stick ? `${styles.show}` : ""}`} />
      <div
        className={styles.right + ` ${contentShow ? `${styles.show}` : ""}`}
        ref={right}
      >
        <div
          className={
            styles.padding +
            ` ${stick ? `${styles.show}` : ""}` +
            ` ${subcategoryOpen ? `${styles.subOpen}` : ""}` +
            ` ${dropOpen ? `${styles.open}` : ""}`
          }
        />
        {block.categories
          .filter((category) => category.blockTitle === activeCategory)
          .map((category) => {
            if (category.hasSubcategories) {
              return category.subcategories
                .filter(
                  (subcategory, i) => subcategory.category === activeSubcategory
                )
                .map((subcategory) => (
                  <EducationContent
                    key={subcategory}
                    block={subcategory}
                    scroll={scroll}
                    category={activeCategory}
                    subcategory={activeSubcategory}
                  />
                ));
            } else
              return (
                <EducationContent
                  key={category}
                  block={category}
                  scroll={scroll}
                  category={activeCategory}
                  subcategory={activeSubcategory}
                />
              );
          })}
      </div>
    </section>
  );
};
