import React, { useEffect, useState, useRef } from 'react'
import * as styles from './video.module.scss'

export default ({ url, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .9)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <div className={styles.videoContainer + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.videoWrapper}>
        <iframe 
          width="560" 
          height="349" 
          src={`https://www.youtube.com/embed/${url.split('/')[url.split('/').length - 1]}`}
          allowFullScreen>
        </iframe>
      </div>
    </div>
  )
}