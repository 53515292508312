import React, { useEffect, useState, useRef } from 'react'
import * as styles from './wistiaVideo.module.scss'

export default ({ id, scroll }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [placeholder, setPlaceholder] = useState(true);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .9)) {
      setShow(true);
      // setTimeout(() => {
      //   setPlaceholder(false);
      // }, 1000)
    }
  }, [scroll])

  return (
    <div className={styles.videoContainer + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.videoWrapper}>
        <iframe 
          width="560" 
          height="349" 
          src={`//fast.wistia.net/embed/iframe/${id}?videoFoam=true&autoPlay=true&settingsControl=false&smallPlayButton=false&playbar=false&volumeControl=false&fullscreenButton=false&endVideoBehavior=loop&muted=true`}
          allowFullScreen
          class="wistia_embed wistia_async_abcde12345"
          name="wistia_embed"
        >
        </iframe>
      </div>
      {/* <img src='/preserving-placeholder.png' className={styles.placeholder + ` ${!placeholder ? `${styles.hide}` :''}`} /> */}
      <div className={styles.overlay} />
    </div>
  )
}