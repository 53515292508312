import React, { useState, useEffect, useRef } from 'react'

import { Content, FormatDate, getDay, getTime } from '../../../../utilities'

const styles = require('./vomo.module.scss')

export default ({ block, scroll, category, subcategory, moveStart = 3, padding = false }) => {
  const ref = useRef(null)
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(true)
  const [show, setShow] = useState(false)
  const [disable, setDisable] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const [events, setEvents] = useState([])
  const [start, setStart] = useState(typeof window !== 'undefined' && window.innerWidth <= 767 ? 1 : moveStart)
  const [moves, setMoves] = useState(typeof window !== 'undefined' && window.innerWidth <= 767 ? 1 : moveStart)

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
      setShow(true)
    }
  }, [scroll])

  const compareDate = (a, b) => {
    const timeOne = a.Start_Time__c
    const timeTwo = b.Start_Time__c
    let comparison = 0
    if (new Date(timeOne) > new Date(timeTwo) && !timeOne) {
      comparison = 1
    } else if (new Date(timeOne) < new Date(timeTwo)) {
      comparison = -1
    }
    return comparison
  }

  useEffect(() => {
    fetch('/.netlify/functions/getvomoevents', {
      method: 'POST'
    })
      .then(res => res.json())
      .then(response => {
        setEvents(response.data.sort(compareDate))
      })
  }, [])

  const colors = ['#32B44A', '#FFA225', '#067BC2']

  const getColor = i => {
    if (i <= 2) return colors[i]
    else if (i % 3 === 0) return colors[0]
    else if ((i - 1) % 3 === 0) return colors[1]
    else return colors[2]
  }

  useEffect(() => {
    const reset = () => {
      if (ref.current) {
        ref.current.scrollLeft = 0
        setMoves(start)
        setShowLeft(false)
        setShowRight(true)
        if (typeof window !== 'undefined' && window.innerWidth > 767 && events.length <= start) {
          setShowButtons(false)
        } else if (typeof window !== 'undefined' && window.innerWidth > 767 && events.length > start) {
          setShowButtons(true)
        } else if (typeof window !== 'undefined' && window.innerWidth <= 767 && events.length <= 1) {
          setShowButtons(false)
        } else if (events.length > 1 && window.innerWidth <= 767) {
          setShowButtons(true)
          setStart(1)
          setMoves(1)
        }
      }
    }
    reset()
  }, [events])

  useEffect(() => {
    if ((category || subcategory) && ref.current) {
      ref.current.scrollLeft = 0
      setShowRight(true)
      setShowLeft(false)
      setMoves(start || 3)
    }
  }, [start, category, subcategory])

  const moveLeft = () => {
    setDisable(true)
    let distance = 0
    if (window.innerWidth > 767) distance = (window.innerWidth / 100) * 30.5
    else distance = window.innerWidth
    if (ref.current) {
      setShowRight(true)
      ref.current.scrollLeft = ref.current.scrollLeft - distance
      setMoves(moves - 1)
      if (start && moves === start + 1) setShowLeft(false)
      else if (!start && moves === 4) setShowLeft(false)
    }
    setTimeout(() => {
      setDisable(false)
    }, 300)
  }

  const moveRight = () => {
    setDisable(true)
    let distance = 0
    if (window.innerWidth > 767) distance = (window.innerWidth / 100) * 30.5
    else distance = window.innerWidth
    if (ref.current) {
      setShowLeft(true)
      ref.current.scrollLeft = ref.current.scrollLeft + distance
      setMoves(moves + 1)
      if (moves === events.length - 1) setShowRight(false)
    }
    setTimeout(() => {
      setDisable(false)
    }, 300)
  }

  return (
    <section className={styles.section + ` ${events.length === 0 ? ` ${styles.hide}` : ''}`} id={block.template && block.template.replace('main-', '')}>
      {block.eventsHeading && (
        <h2 className={styles.heading}>
          <Content text={block.eventsHeading} />
        </h2>
      )}
      <div className={styles.events + ` ${show ? `${styles.show}` : ''}` + ` ${padding ? `${styles.noPadding}` : ''}`} ref={ref}>
        {events.length > 0 &&
          events.map((event, i) => (
            <a href={event.Event_Url__c} key={i} className={styles.event} style={{ backgroundColor: getColor(i), transitionDelay: `.${i}s` }} target='__blank' rel='noreferrer'>
              <div className={styles.date}>
                <p className={styles.weekday}>{!event.Start_Time__c ? <Content text='Anytime' /> : <Content text={getDay(event.Start_Time__c)} />}</p>
                <p className={styles.day}>
                  <FormatDate date={event.Start_Time__c} />
                </p>
              </div>
              <div className={styles.container}>
                <p className={styles.title}>
                  <Content text={event.Name} />
                </p>
                <p className={styles.description}>
                  <Content text={event.c4g_Course_Description__c} />
                </p>
                <div className={styles.location}>{!event.Start_Time__c ? null : getTime(event.Start_Time__c)}</div>
              </div>
            </a>
          ))}
        <div className={styles.padding} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.footerText}>{block.eventsFooterText && <Content text={block.eventsFooterText} />}</div>
        {showButtons && (
          <div className={styles.buttons}>
            <button className={styles.left + ` ${!showLeft ? `${styles.hide}` : ''}` + ` ${disable ? `${styles.disable}` : ''}`} onClick={moveLeft}>
              <img src='/button.svg' className={styles.leftIcon} />
            </button>
            <button className={styles.right + ` ${!showRight ? `${styles.hide}` : ''}` + ` ${disable ? `${styles.disable}` : ''}`} onClick={moveRight}>
              <img src='/button.svg' className={styles.rightIcon} />
            </button>
            <span className={styles.count}>
              {moves.toString()}/{events.length}
            </span>
          </div>
        )}
      </div>
      {block.eventsHasBackground && <div className={styles.background} />}
    </section>
  )
}
