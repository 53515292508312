// extracted by mini-css-extract-plugin
export var container = "bodyData-module--container--zoLzi";
export var icon = "bodyData-module--icon--YG4XA";
export var includes = "bodyData-module--includes--qt1Qz";
export var item = "bodyData-module--item--zc-Pv";
export var list = "bodyData-module--list--Ewoin";
export var listItem = "bodyData-module--listItem--KzNUH";
export var narrow = "bodyData-module--narrow--+FKbV";
export var show = "bodyData-module--show--O4bLg";
export var small = "bodyData-module--small--L2brZ";
export var text = "bodyData-module--text--M2MpT";