import React, { useState, useEffect } from 'react'
import * as styles from './soilSelector.module.scss'
import { Content } from '../../../../../../utilities'

export default ({ block, soilTypes, setSoilTypes, setData }) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleClick = treeType => {
    setSoilTypes(types => {
      const copy = [...types];
      copy.push(treeType);
      return copy;
    })
  }

  const handleRemove = treeType => {
    setSoilTypes(types => {
      const copy = types.filter(type => type !== treeType);
      return copy;
    })
  }

  useEffect(() => {
    setData();
  }, [soilTypes])

  return (
    <div className={styles.container}>
      <div onClick={() => setSelectOpen(!selectOpen)} className={styles.select + ` ${selectOpen === true ? `${styles.open}` : ""}`}>
      {!selectOpen &&
        <img src={'/chevron-right.svg'} className={styles.arrow} />}
        {selectOpen &&
        <img src={'/chevron-right-white.svg'} className={styles.arrow} />}
        <p className={styles.placeholder + ` ${selectOpen ? `${styles.open}` : ''}`}><Content text='Soil types:' /></p>
        {block.soilTypes.filter(soilType => !soilTypes.includes(soilType)).map(soilType => (
          <button
            key={soilType}
            className={styles.type + ` ${selectOpen ? `${styles.open}` : ''}`}
            onClick={() => handleClick(soilType)}
          ><Content text={soilType} /></button>
        ))}
      </div>
      {soilTypes.length > 0 ? soilTypes.map((treeType, i) => (
        <div key={i} className={styles.typeFilter}>
          <button 
            className={styles.remove}
            onClick={() => handleRemove(treeType)}
            style={{backgroundImage: 'url(/x.svg)'}}
          />
          <Content text={treeType} />
        </div>
      )) : null}
    </div>
  )
}