import React, { useEffect, useRef, useState } from 'react'
import * as styles from './footerForm.module.scss'
import { Content, checkEmail, checkValidInputs, checkZip, handleChange, handleOptionClick } from '../../../../utilities'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ block, scroll, title=null, buttonText=null }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectOpen, setSelectOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleInputChange = e => {
    handleChange(e, setFormValues);
  }

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  useEffect(() => {
    if (block.inputs) {
      const inputs = {};
      block.inputs.forEach(input => {
        if (input.label)
        inputs[input.label] = '';
      })
      setFormValues(inputs);
    }
  }, [])

  const isObject = label => {
    return typeof formValues[label] === 'object' && formValues[label] !== null
  }

  const checkObject = object => {
    let hasChecked = false;
    for (let [key, value] of Object.entries(object)) {
      if (value)
        hasChecked = true;
    }
    return hasChecked;
  }

  const checkIfValid = label => {
    return (
      checkValidInputs(label, formValues, checkEmail, checkZip, isObject, checkObject)
    )
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    block.inputs.forEach(input => {
      if (!checkIfValid(input.label)) {
        setShowErrorMessage(true);
        error = true;
      }
    })
    if (!error) {
      setShowErrorMessage(false);
      setLoader(true);
      fetch('/.netlify/functions/contact', {
        method: 'POST',
        body: JSON.stringify({
          ...formValues,
          emails: block.emails,
          form: title ? title : block.formTitle
        })
      })
      .then(response => {
        setLoader(false);
        setSubmitted(true);
        const inputs = {};
        if (block.inputs) {
          const inputs = {};
          block.inputs.forEach(input => {
            if (input.label)
            inputs[input.label] = '';
          })
          setFormValues(inputs);
        }
      })
    }
  }

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.left + ` ${!block.formImage ? `${styles.wide}` : ''}`}>
        {block.formHeading &&
        <h2 className={styles.heading}>
          <Content text={block.formHeading} />
        </h2>}
        {block.submitTo && block.submitTo.includes('donorbox') ? (
        <div className={styles.formContainer}>
          <div className={styles.formWrapper}>
            <iframe
              height="349"
              name="donorbox"
              scrolling="yes"
              src={block.submitTo}
              width="560">
            </iframe>
          </div>
          {/* <button
            style={{backgroundImage: '/x.svg'}}
            className={styles.formClose}
            onClick={reset}>
              close
          </button> */}
        </div> ) : 
        <form className={styles.form} onSubmit={handleSubmit}>
          {block.inputs.map(input => (
            <div key={input.label} className={styles.colOne}>
              <label className={styles.label} htmlFor={input}>
                <Content text={input.label} />
              </label>
              {input.type === 'dropdown' ? (
                <>
                  <input 
                    className={styles.dropdownInput} 
                    name={input.label}
                    value={formValues[input.label]}
                    onChange={handleInputChange}
                  />
                  <div 
                    onClick={() => setSelectOpen(!selectOpen)} 
                    className={styles.select + ` ${selectOpen ? `${styles.open}` : formValues[input.label] ? `${styles.selected}` : ''}`}
                  >
                    <img
                      src={"/chevron-down-1.svg"} className={styles.arrow} 
                    />
                    <p className={styles.placeholder + ` ${formValues[input.label] ? `${styles.hide}` : ''}`}>{input.placeholder}</p>
                    {input.options.map((option) => (
                      <button 
                        key={option} 
                        type='button' 
                        onClick={() => handleOptionClick(input.label, option, selectOpen, setSelectOpen, setFormValues)} 
                        className={styles.dropdownOption + ` ${formValues[input.label] === option ? `${styles.active}` : ''}` + ` ${selectOpen ? `${styles.open}` : ''}`}>
                        {option}
                      </button>
                    ))}
                  </div>
                </>
              ) : (
              <input
                className={styles.input}
                value={formValues[input.label]}
                name={input.label}
                id={input.label}
                onChange={handleInputChange}
                type={input.type}
              />)}
            </div>
          ))}
        
          <button
            className={styles.button + ` ${!block.formButtonText ? `${styles.noText}` : ''}`}
          >
            <Content text={buttonText ? buttonText : block.formButtonText} />
            <span><img src='/button.svg' className={styles.icon} /></span>
          </button>
          {loader &&
          <div className={styles.loader}>
            <Loader
              type="TailSpin"
              color="#0C3507"
              height={50}
              width={50}
            />
          </div>}
          {showErrorMessage &&
          <p className={styles.message}>
            <Content text='Please enter valid input' />.
          </p>}
          {submitted &&
          <p className={styles.message}>
            <Content text={`Thanks, we'll be in touch!`} />.
          </p>}
        </form>}
        {block.formFooterText &&
        <div className={styles.footerText}>
          <Content text={block.formFooterText} />
        </div>}
      </div>
      {block.formImage &&
      <div className={styles.right}>
        <img src={block.formImage} className={styles.image} />
      </div>}
    </section>
  )
}