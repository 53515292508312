import React, { useContext } from 'react'
import { Context } from '../../../../context'
import * as styles from './donate.module.scss'
import { Content } from '../../../../utilities'

export default ({ block }) => {
  const context = useContext(Context);
  
  const handleClick = () => {
    if (context) {
      context.setDonateOpen(!context.donateOpen);
      context.setDonateUrl('');
    }
  }

  return (
    <section
      className={styles.section}
      onMouseDown={handleClick}
      style={{
        zIndex: context && context.donateOpen ? 99 : 0
      }}>
      {context && context.donateOpen ? (
        <img src='/x.svg' className={styles.close} />
      ) : (
      <>
        <img src={block.icon} className={styles.icon} />
        <p className={styles.label}>
          <Content text={block.label} />
        </p>
      </>
      )}
    </section>
  )
}