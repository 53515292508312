import React from 'react'
import * as styles from './amazon.module.scss'
import { Content } from '../../../../utilities'

export default ({ block, show }) => {

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
      <a href={block.url} target='_blank' rel='noreferrer'>
        <div className={styles.text}>
          <Content text={block.text} />
        </div>
        <img src={block.logo} className={styles.logo} />
      </a>
    </section>
  )
}