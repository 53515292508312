import React, { useEffect, useRef, useState} from 'react'
import * as styles from './keystones.module.scss'
import { Content } from '../../../../../../utilities'
import { Link } from 'gatsby'

export default ({ block, scroll }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref}>
      <div className={styles.headingContainer}>
        <div className={styles.numContainer}>
          <img src={'./button-orange.svg'} className={styles.orange} />
          <p className={styles.number}>1</p>
        </div>
        <h2 className={styles.heading}>
          <Content text={block.keystonesHeading} />
        </h2>
      </div>
      <div className={styles.keystones}>
          {block.keystones.map(keystone => (
            <div key={keystone.keystone} className={styles.keystone}>
              <div className={styles.container}>
                <img src={keystone.icon} className={styles.icon} />
                <h4 className={styles.label}>
                  <Content text={keystone.keystone} />
                </h4>
                <div className={styles.text}>
                  <Content text={keystone.text} />
                </div>
              </div>
              <ul>
                {keystone.listItems.map(item => (
                  <li key={item}>
                    <span className={styles.marker} />
                    <p>
                      <Content text={item} />
                    </p>
                  </li>
                ))}
              </ul>
              {keystone.links.map((link, i) => (
                <Link key={i} to={link.url} className={styles.link}>
                  <Content text={link.label} />
                </Link>
              ))}
            </div>
          ))}
        </div>
    </section>
  )
}