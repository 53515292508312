import React from 'react'
import * as styles from './topImage.module.scss'

export default ({ image }) => {


  return (
    <div className={styles.top}>
      <img src={image} className={styles.firstImage} />
      <div className={styles.background} />
    </div>
  )
}
