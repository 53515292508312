import React from 'react'

export default ({ date }) => {
  if (date) {
    let month = '';
    let day = '';

    for (let i = 5; i <= 9; i++) {
      if (i === 5 && +date[i] !== 0)
        month += date[i];
      if (i === 6)
        month += date[i];
      if (i === 8 && +date[i] !== 0)
        day += date[i];
      if (i === 9)
        day += date[i];
    }

    return (
      <>
        {`${month}/${day}`}
      </>
    )
  } else return null
}