// extracted by mini-css-extract-plugin
export var bodyHeading = "sponsorContent-module--bodyHeading--vOC6S";
export var bodyLeft = "sponsorContent-module--bodyLeft--yNJkv";
export var bodyRight = "sponsorContent-module--bodyRight--EAS-H";
export var contact = "sponsorContent-module--contact--NrdnW";
export var contactLabel = "sponsorContent-module--contactLabel--MP3sn";
export var contactText = "sponsorContent-module--contactText--6Y8GQ";
export var contain = "sponsorContent-module--contain--5ZiPe";
export var evergreen = "sponsorContent-module--evergreen--h7wDt";
export var firstImage = "sponsorContent-module--firstImage--aXaiC";
export var footerText = "sponsorContent-module--footerText--iIndz";
export var locations = "sponsorContent-module--locations--fF9ha";
export var main = "sponsorContent-module--main--q2v-h";
export var mapContainer = "sponsorContent-module--mapContainer--lgV-D";
export var mapWrapper = "sponsorContent-module--mapWrapper--DgACy";
export var secondImage = "sponsorContent-module--secondImage--EexBN";
export var section = "sponsorContent-module--section--OjkoP";