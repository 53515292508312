import React, { useEffect, useRef, useState } from 'react'
import * as styles from './simpleForm.module.scss'
import { checkEmail, checkValidInputs, checkZip, Content } from '../../../../utilities'

export default ({ block, scroll }) => {

  const initValues = () => {
    const inputs = {};
    if (block.inputs.length) {
      block.inputs.forEach(input => {
        inputs[input] = '';
      })
      return inputs;
    }
  }

  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState(initValues());
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState('');

  useEffect(() => {
    if (scroll && ref.current && ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
      setShow(true);
    }
  }, [scroll])

  const handleChange = e => {
    const target = e.target;
    setFormValues(values => {
      const copy = {...values};
      copy[target.name] = target.value;
      return copy;
    })
  }

  const checkIfValid = label => {
    return (
      checkValidInputs(label, formValues, checkEmail, checkZip, isObject, checkObject)
    )
  }

  const isObject = label => {
    return typeof formValues[label] === 'object' && formValues[label] !== null
  }

  const checkObject = object => {
    let hasChecked = false;
    for (let [key, value] of Object.entries(object)) {
      if (value)
        hasChecked = true;
    }
    return hasChecked;
  }

  const salesforceSubmit = (route, message, emails=null) => {
    fetch(`/.netlify/functions/${route}`, {
      method: 'POST',
      body: JSON.stringify({
        ...formValues,
        emails
      })
    })
    .then(res => res.json())
    .then(response => {
      // console.log(response)
      setSubmitted(message);
      const inputs = {};
      if (block.inputs.length) {
        block.inputs.forEach(input => {
          inputs[input] = '';
        })
        setFormValues(inputs);
      }
    })
    .catch (error => {
      setErrorMessage('There was a problem processing your request.')
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    for (let [key, value] of Object.entries(formValues)) {
      if (!checkIfValid(key)) {
        setErrorMessage('Enter valid input.');
        error = true;
      }
    }
    if (!error) {
      setErrorMessage('');

      if (block.submitTo === 'Salesforce Hours') {
        salesforceSubmit('gethours', 'Check your email!');

      } else if (block.submitTo.includes('mailerlite')) {
        fetch('/.netlify/functions/mailerlite', {
          method: 'POST',
          body: JSON.stringify({
            email: formValues['Email'],
            name: formValues['Full name'],
            groupId: block.submitTo.split('/')[1]
          })
        })
        .then(response => {
          salesforceSubmit('contact', 'Thanks for signing up!', ['trees@upwithtrees.org'])
        })
        .catch(error => {
          setErrorMessage('There was an error processing your request.');
        })
      }
    }
  }
 
  return (
    <section className={styles.section + ` ${show ? `${styles.show}` : ''}`} ref={ref} id={block.template && block.template.replace('main-', '')}>
      <h2 className={styles.heading}>
        <Content text={block.blockTitle ? block.blockTitle : ''} />
      </h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        {block.inputs.length > 0 && block.inputs.map(input => (
          <div key={input} className={styles.colOne}>
            <label className={styles.label} htmlFor={input}>
              <Content text={input} />
            </label>
            <input
              className={styles.input}
              value={formValues[input]}
              name={input}
              id={input}
              onChange={handleChange}
            />
          </div>
        ))}
        {submitted &&
        <p className={styles.submitted}><Content text={submitted} /></p>}
        <button
          className={styles.button}
        >
          <Content text={block.buttonText} /><span><img src='/button.svg' className={styles.icon} /></span>
        </button>
        {errorMessage &&
        <p className={styles.message}><Content text={errorMessage} /></p>}
      </form>
    </section>
  )
}