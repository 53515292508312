// extracted by mini-css-extract-plugin
export var container = "team-module--container--idV2X";
export var gradient = "team-module--gradient--gVMkI";
export var heading = "team-module--heading--bmUbq";
export var image = "team-module--image--rCo6Q";
export var member = "team-module--member--M2aJ8";
export var name = "team-module--name--AfZyc";
export var padding = "team-module--padding--TdmBP";
export var section = "team-module--section--+9ccJ";
export var show = "team-module--show--hGZDY";
export var text = "team-module--text--Ns-yU";
export var title = "team-module--title--ThyiU";